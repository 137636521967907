@import '../abstracts/variables';

:root {
  color-scheme: light only;
}

.basic {
  &__list {
    li {
      @extend .cursor-pointer;
      margin-bottom: $spacer-3;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: $header-height;
    background-color: $component-bg-color;
    border-bottom: 1px solid $component-bg-fourth;
    box-shadow: none;
    &_logo {
      height: $header-logo-height;
    }
    &_content {
      margin: auto;
      max-width: $max-content-width;
    }
    &_content {
      max-width: $max-content-width;
      margin: auto;
    }
  }
  &__layout-small {
    min-width: $common-page-content-width;
    width: $common-page-content-width;
    max-width: $common-page-content-width;
    @media (max-width: $screen-lg) {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
  &__layout {
    background: $page-bg-color;
    margin-top: $header-height;
    width: 100%;
    height: calc(100% - #{$header-height});
    overflow-y: auto;
    display: block;
    &__col {
      @media (min-width: $screen-lg) {
        padding: 0 $spacer-5;
      }
    }
  }
}
