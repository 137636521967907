@import '../abstracts/variables';

//COLORS
.color-orange {
  color: $color-orange !important;
}

.color-text-additional {
  color: $text-color-additional !important;
}

.color-light-blue {
  color: $color-blue !important;
}

.color-link {
  color: $icon-color-additional !important;
}

.color-text-secondary {
  color: $text-color-secondary !important;
}

.color-bw-white {
  color: $text-color-bw-white;
}

.color-white {
  color: $gray-1;
}

.color-black-40 {
  color: $color-true-black-04;
}

.color-whiteDark {
  color: $gray-3 !important;
}

.color-grayLight {
  color: $gray-6 !important;
}

.color-gray {
  color: $gray-7 !important;
}

.color-grayDark {
  color: $gray-8 !important;
}

.color-black {
  color: $gray-11 !important;
}

.color-text-primary {
  color: $text-color-primary !important;
}

.color-blackLight {
  color: $gray-10 !important;
}

.color-black-primary {
  color: $color-true-black-085 !important;
}

.color-dark {
  color: $gray-9 !important;
}

.color-disable {
  color: $text-color-disable !important;
}

.color-primary {
  color: $color-primary;
}

.color-warning {
  color: $color-orange !important;
}

.color-success {
  color: $color-green !important;
}

.color-danger {
  color: $color-danger !important;
}

.color-light-danger {
  color: $red-5 !important;
}

.text-underline {
  text-decoration: underline;
}
.text-line-through {
  text-decoration: line-through;
}

.text-capitalize {
  text-transform: capitalize;
}

.t-decoration-none {
  text-decoration: none !important;
}

.classic-link {
  color: $color-link !important;
  &:hover {
    color: darken($color-link, 5) !important;
  }
  &:active {
    color: darken($color-link, 10) !important;
  }
}

.line-height {
  &-100 {
    line-height: 100% !important;
  }
  &-75 {
    line-height: 75% !important;
  }
}

//FONTS
.font-weight {
  &-default {
    font-weight: normal !important;
  }
  &-4 {
    font-weight: 400 !important;
  }
  &-5 {
    font-weight: 500 !important;
  }
  &-6 {
    font-weight: 600 !important;
  }
  &-7 {
    font-weight: 700 !important;
  }
  &-8 {
    font-weight: 800 !important;
  }
  &-9 {
    font-weight: 900 !important;
  }
  &-bold {
    font-weight: bold !important;
  }
}

.font-style {
  &-default {
    font-style: normal !important;
  }
  &-i {
    font-style: italic !important;
  }
}

.fs {
  font-size: 12px !important;
  &-xxxxs {
    font-size: $fontSize-xxxxs !important;
  }
  &-xxxs {
    font-size: $fontSize-xxxs !important;
  }
  &-xxs {
    font-size: $fontSize-xxs !important;
  }
  &-xs {
    font-size: $fontSize-xs !important;
  }
  &-sm {
    font-size: $fontSize-sm !important;
  }
  &-md {
    font-size: $fontSize-md !important;
  }
  &-lg {
    font-size: $fontSize-lg !important;
  }
  &-xlg {
    font-size: $fontSize-xlg !important;
  }
  &-priority {
    font-size: 1.1em !important;
  }
}

.font-main {
  font-family: $font-main, $font-backup !important;
}

.font-secondary {
  font-family: $font-secondary, $font-backup !important;
}

// Player profile fonts
.pp-font-color-main {
  color: $text-color-primary;
}

.pp-font-color-gray-4 {
  color: $pp-color-gray-4;
}

.pp-font {
  &-title-1 {
    font-weight: 400;
    font-style: normal;
    font-size: 32px;
    line-height: 124.5%;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-title-2 {
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-title-3 {
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-title-4 {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 124.5%;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-title-5 {
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-text {
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.13px;
    @extend .pp-font-color-main;
  }
  &-description {
    font-weight: 400;
    font-style: normal;
    font-size: 11px;
    line-height: 100%;
    letter-spacing: 0.01em;
    @extend .pp-font-color-main;
  }
  &-paragraph {
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    line-height: 100%;
    @extend .pp-font-color-main;
  }
  &-small {
    font-weight: 400;
    font-style: normal;
    font-size: 8px;
    line-height: 100%;
    @extend .pp-font-color-main;
  }
}

.card-small-py {
  & > .ant-card-body {
    padding-top: $spacer-2 !important;
    padding-bottom: $spacer-2 !important;
  }
}

@mixin spacerBuilder($property) {
  &-0 {
    #{$property}: $spacer-0 !important;
  }
  &-1 {
    #{$property}: $spacer-1 !important;
  }
  &-2 {
    #{$property}: $spacer-2 !important;
  }
  &-3 {
    #{$property}: $spacer-3 !important;
  }
  &-4 {
    #{$property}: $spacer-4 !important;
  }
  &-5 {
    #{$property}: $spacer-5 !important;
  }
  &-6 {
    #{$property}: $spacer-6 !important;
  }
  &-7 {
    #{$property}: $spacer-7 !important;
  }
  &-8 {
    #{$property}: $spacer-8 !important;
  }
  &-9 {
    #{$property}: $spacer-9 !important;
  }
  &-10 {
    #{$property}: $spacer-10 !important;
  }
  &-11 {
    #{$property}: $spacer-11 !important;
  }
  &-12 {
    #{$property}: $spacer-12 !important;
  }
  &-13 {
    #{$property}: $spacer-13 !important;
  }
}

.m {
  @include spacerBuilder(margin);
}
.mt {
  @include spacerBuilder(margin-top);
}

.mr {
  @include spacerBuilder(margin-right);
}

.mb {
  @include spacerBuilder(margin-bottom);
}

.ml {
  @include spacerBuilder(margin-left);
}

.p {
  @include spacerBuilder(padding);
}

.pt {
  @include spacerBuilder(padding-top);
}

.pr {
  @include spacerBuilder(padding-right);
}

.pl {
  @include spacerBuilder(padding-left);
}

.pb {
  @include spacerBuilder(padding-bottom);
}

.px {
  @include spacerBuilder(padding-left);
  @include spacerBuilder(padding-right);
}

.py {
  @include spacerBuilder(padding-top);
  @include spacerBuilder(padding-bottom);
}
.my {
  @include spacerBuilder(margin-top);
  @include spacerBuilder(margin-bottom);
}
.mx {
  @include spacerBuilder(margin-left);
  @include spacerBuilder(margin-right);
}

@mixin displayMixin() {
  &-none {
    display: none !important;
  }
  &-block {
    display: block !important;
  }
  &-flex {
    display: flex !important;
  }
}

.d {
  @each $name, $value in $screens {
    &-#{$name} {
      @media (max-width: $value) {
        @include displayMixin();
      }
    }
  }
}

.font-size {
  @each $name, $value in $fontSizes {
    &-#{$name} {
      font-size: $value !important;
    }
  }
}

$tags-colors: (
  default: $color-blue,
  green: $cyan-5,
  yellow: $yellow-5,
  orange: $orange-5,
  red: $red-6
);

@mixin getTagColor($color) {
  color: $color;
  border-color: $color;
}

@media (max-width: 400px) {
  .text-header {
    width: 260px;
  }
}

.box-shadowed {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1) !important;

  &--interactive {
    &:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) !important;
    }
  }
}

.rounded {
  background: $gray-1;
  border: 1px solid $gray-3;
  box-sizing: border-box;
  border-radius: $border-radius-content;
}

@media (max-width: $screen-md) {
  .rounded > .ant-card-body {
    padding: $spacer-6;
  }
}

.zIndex-100 {
  z-index: 100;
}

.pre-line-space {
  white-space: pre-line;
}

.input:focus {
  outline: none;
  border: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.overflow-auto {
  overflow: auto;
}
.flex-shrink {
  flex-shrink: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.height-full {
  height: 100% !important;
}

.height-min-content {
  height: min-content;
}

.width-full {
  width: 100%;
}

.width-fit {
  width: fit-content;
}

.height-fit {
  height: fit-content;
}

.width-fill-available {
  width: -webkit-fill-available;
  width: -moz-available;
}

.width-max-full {
  max-width: 100%;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-space-between {
  justify-content: space-between;
}

.flex-align-center {
  align-items: center;
}
.align-start {
  align-items: start;
}

.align-self {
  &__end {
    align-self: flex-end;
  }
}
.flex-row-reverse {
  flex-direction: row-reverse;
}

.t-align-c {
  text-align: center;
}

.t-align-e {
  text-align: end;
}

.t-align-s {
  text-align: start;
}

.t-nowrap {
  white-space: nowrap;
}

.t-pre-wrap {
  white-space: pre-wrap;
}

.word-break {
  word-break: break-word;
}

.border-none {
  border: none !important;
}

.bg-component {
  background: $component-bg-color !important;
}

.bg-page {
  background-color: $page-bg-color !important;
}

.bg-component-secondary {
  background-color: $component-bg-secondary;
}

.bg-secondary-gray {
  background-color: $gray-10;
}

.bg-component-third {
  background-color: $component-bg-third;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-danger {
  background-color: $color-danger;
}

.bg-success {
  background-color: $color-green;
}

.bg-card {
  background-color: $gray-12;
}

.bg-layout {
  background-color: $page-bg-color;
}

.bg-warning {
  background-color: $color-orange;
}

.bg-blue {
  background-color: $text-color-additional;
}

.radius-none {
  border-radius: 0 !important;
}

.radius-small {
  border-radius: $border-radius-xs !important;
}

.radius-default {
  border-radius: $border-radius !important;
}
.fit-cover {
  object-fit: cover !important;
}

.radius-round {
  border-radius: 100px;
}

.border-none {
  border: none !important;
}

.border-circle {
  border-radius: 50% !important;
}

.border-light {
  border: 1px solid $border-light-color;
}

.bordered {
  border: 1px solid $border-color;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.modal-mobile {
  width: 100% !important;
  min-height: 100%;
  height: 100%;
  margin: 0;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  block-size: auto;
  & .ant-modal-content {
    height: 100%;
    min-height: 100vh;
    max-height: 100%;
    & .ant-modal-header {
      padding-bottom: $spacer-5;
    }
  }
}

.word-break-all {
  word-break: break-all;
}

.uppercase {
  text-transform: uppercase;
}

.spin-opacity-0 {
  & > .ant-spin-blur {
    opacity: 0;
  }
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.line-height-small {
  line-height: 100%;
}

.mobile-header-padding {
  @media (max-width: $screen-lg) {
    padding-left: $spacer-6;
  }
}

.hide-on-print {
  @media print {
    display: none !important;
  }
}

.min-width-content {
  min-width: min-content;
}

.width-content {
  width: min-content;
}
