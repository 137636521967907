@import "../abstracts/variables";

.franchise-academies-page {
  & .instruments-panel {
    @media (max-width: $screen-xs) {
      padding: 0 $spacer-3;
    }
  }
  &__list {
    li {
      @extend .cursor-pointer;
      border: none !important;
      padding: 0 !important;
      margin-bottom: $spacer-3;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
