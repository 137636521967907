@import "../abstracts/variables";

.player-pass-rate {
  padding: $spacer-1 $spacer-4;
  width: 4.25rem;
  white-space: nowrap;
  @extend .radius-round;
  @each $name, $value in $tags-colors {
    &__#{$name} {
      border: 1px solid $value;
      & > span {
        color: $value;
      }
    }
  }
}
