@import "../abstracts/variables";

.academy-ages-select{
  &__age {
    border: 1px solid $border-color;
    background-color: $component-bg-secondary;
    border-radius: $border-radius;
    padding: 0 $spacer-3;
    margin-bottom: $spacer-3;
    &:last-child {
      margin-right: 0;
    }
    &.ant-tag-checkable-checked {
      color: $text-color-bw-white;
      border: 1px solid $component-bg-accent;
      background-color: $component-bg-accent;
    }
  }
};
