@import "../base/utiles";

.bar-chart-component{
  overflow: hidden;
  $chart-height: 22px;
  @media (max-width: $screen-md) {
    $chart-height: 20px;
  }
  &__content{
    background: $component-bg-color;
    height: $chart-height;
    min-height: $chart-height;
    border: 2px solid;
    box-sizing: content-box;
  }
  &__label{
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    @media (max-width: $screen-md) {
      font-size: 14px;
    }
  }
  &__text{
    font-weight: 900;
    font-size: 16px;
    color: $gray-13;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    z-index: 2;
    margin-top: -2px !important;
    margin-left: -1px;
    height: calc(#{$chart-height} + 4px);
  }
  &__value{
    &:after{
      position: absolute;
      content: "";
      width: 8px;
      height: calc(16px + #{$chart-height});
      top: -8px;
      transform: rotate(16deg);
      background: inherit;
      right: -4px;
      font-family: Inter, serif;
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 18px;
      @media (max-width: $screen-md) {
        font-size: 15px;
      }
    }
  }
}
