@import "../abstracts/variables";

.video-private {
  &__modal {
    & .ant-modal-content {
      width: 80%;
      height: 100%;
      padding: $spacer-3;
      margin: auto;
      & .ant-modal-close {
        & .ant-modal-close-x {
          font-size: 24px;
          color: $gray-1;
        }
      }
      @media (max-width: $screen-lg) {
        width: 100%;
        padding: 0;
      }
    }
    & .video-react, & .youtube-player, & .ant-skeleton-element {
      height: 80vh;
      width: 100%;
      @media (max-width: $screen-lg) {
        height: 100vh;
      }
    }
    & .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
    @media (max-width: $screen-lg) {
      margin: 0;
      padding: 0;
      max-width: 100vw;
    }
  }
  & .ant-skeleton-element {
    width: 100% !important;
  }
  &__preview {
    &-container {
      position: relative;
      border-radius: $border-radius;
      overflow: hidden;
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    &-icon {
      background: url("../../icons/play.svg") no-repeat center center;
      background-size: 60%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
  }
}
