@import '../abstracts/variables';

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

* {
  font-family: $font-main, $font-backup;
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $input-color-bg inset;
  box-shadow: 0 0 0 1000px $input-color-bg inset;
  -webkit-text-fill-color: $text-color-primary;
  caret-color: $text-color-primary;
  transition: background-color 5000s ease-in-out 0s;
  &#password {
    border-radius: 0 !important;
    background-color: $input-color-bg !important;
  }
  &:hover,
  &:focus,
  &:active {
    -webkit-box-shadow: 0 0 0 1000px $input-color-bg inset;
    box-shadow: 0 0 0 1000px $input-color-bg inset;
    -webkit-text-fill-color: $text-color-primary;
    transition: background-color 5000s ease-in-out 0s;
  }
  &::first-line {
    font-size: 16px;
    font-family: $font-main, $font-backup;
    font-weight: 400;
  }
}
