@import "../abstracts/variables";

.academies-header {
  @media (max-width: 360px) {
    flex-wrap: wrap !important;
    & > div:first-child {
      margin-bottom: $spacer-2;
    }
  }
}
