@import "../abstracts/variables";

.share-modal {
  &__input {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    & > * {
      &:first-child {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
      &:last-child {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
      }
    }
  }
  &__content{
    padding-bottom: $spacer-3;
    overflow: auto;
    width: 100%;
    justify-content: space-between;
  }
}
