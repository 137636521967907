@import '../abstracts/variables';
@import '../base/utiles';

.component-tags-checkbox {
  & .ant-tag-checkable {
    border-color: $gray-9;
    border-radius: $border-radius;
    &:active {
      @each $name, $value in $tags-colors {
        &.#{$name} {
          background-color: $value;
          color: $gray-1 !important;
        }
      }
    }
    &:hover {
      @each $name, $value in $tags-colors {
        &.#{$name} {
          box-shadow: 0 0 10px;
          @include getTagColor(darken($value, 5%));
          @media (max-width: $screen-lg) {
            box-shadow: none;
            color: $gray-1;
            border-color: $gray-9;
          }
        }
      }
    }

    &.ant-tag-checkable-checked {
      background-color: inherit;

      @each $name, $value in $tags-colors {
        &.#{$name} {
          @include getTagColor($value);
        }
      }
    }
  }
  & > div {
    .ant-tag {
      margin: 0 !important;
    }
    margin-right: $spacer-3 !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
