@import '../abstracts/variables';

.test-result-history-table {
  .ant-table-body {
    overflow: auto !important;
  }
  &__button {
    color: $gray-1 !important;
    border-color: $gray-10 !important;
    background: $gray-10 !important;
  }
  &__name {
    text-align: start;
    text-transform: capitalize;
  }
  .ant-modal-content {
    min-height: 95vh;
  }
  &__best {
    @extend .test-result-history-table__badge;
    background: $pp-color-green-lite !important;
  }
  &__high {
    @extend .test-result-history-table__badge;
    background: $pp-color-gold !important;
  }
  &__together {
    @extend .test-result-history-table__badge;
    background: $pp-color-green-lite !important;
    border: 5px solid $pp-color-gold !important;
  }
  &__header {
    font-size: $fontSize-md;
    font-weight: 500;
    color: $gray-13;
    @media (max-width: $screen-xs) {
      font-size: $fontSize-xxs;
    }
  }
  &__badge {
    width: fit-content;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: $spacer-2 $spacer-4;
    background: $pp-color-gray-6;
    border-radius: $spacer-5;
  }
  &__row {
    & > td {
      background: $gray-5 !important;
    }
  }
  .ant-table-cell {
    padding: $spacer-2 $spacer-3 !important;
  }
}
