@import "../abstracts/variables";

.unauthorized-player-profile {
  &__tabs-container {
    .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-content-holder{
      display: none;
    }
    .ant-tabs-tab-active {
      background-color: $component-bg-color;
    }
  }
  &__tabs {
    min-width: $player-page-tabs-width;
    width: $player-page-tabs-width;
    max-width: $player-page-tabs-width;
    margin-right: $player-page-tabs-margin-right;
  }
  &__content {
    min-width: $player-page-content-width;
    width: $player-page-content-width;
    max-width: $player-page-content-width;
  }
  &.mobile {
    width: 100%;
    .unauthorized-player-profile__tabs {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      margin-right: $spacer-0;
    }
    .unauthorized-player-profile__content {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
