@import "../base/utiles";

.instruments-panel{
  @media (max-width: $screen-md) {
    justify-content: center;
    flex-direction: column;
    & > * {
      &:first-child{
        padding-bottom: $spacer-4;
      }
      flex: 1;
      width: 100%;
    }
  }
}