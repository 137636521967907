@import "../abstracts/variables";

.trainer-academy-page {
  &__block {
    background-color: $component-bg-color;
    border-radius: $border-radius;
    &__data {
      flex: 1;
      & .ant-form-item {
        flex: 1;
        margin-bottom: 0;
      }
    }
  }
}
