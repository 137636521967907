@import "../abstracts/variables";

.help-button {
  position: fixed;
  z-index: 1000;
  bottom: 4%;
  right: 2%;

  &__button {
    font-size: 32px;
    height: fit-content;
    padding: $spacer-4;
  }

  &__close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 1001;
    padding: $spacer-2;
    background-color: $component-bg-third;
    font-size: 10px;

    .anticon-close {
      display: block;
      height: 10px;
    }
  }

  .anticon-message {
    display: block;
    height: 32px;
  }
}