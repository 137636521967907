@import "../abstracts/variables";

.player-skills-development {
  &__tabs {
    @media (min-width: $screen-md) {
      .ant-tabs-nav-list {
        flex-flow: row wrap;
        .ant-tabs-tab {
          margin-bottom: $spacer-2;
        }
      }
    }
  }
}