@import "../abstracts/variables";

$pp-test-item-video-block-width: 150px + $spacer-3;
$pp-test-item-item-border: 1px solid $pp-color-gray-5;
.player-profile-test-item {
  padding-bottom: $spacer-6;
  border-bottom: 1px solid rgba($pp-color-black, .2);
  @media (max-width: $screen-xl) {
    padding-bottom: 0;
    border-bottom: none;
  }
  &__date-block {
    @media (max-width: $screen-xl) {
      width: 100% !important;
      max-width: 100%;
    }
  }
  &__video {
    position: relative;
    @media (max-width: $screen-xl) {
      margin-bottom: $spacer-4;
    }
    & .video-private__preview-container {
      @media (max-width: $screen-xl) {
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        min-height: auto !important;
        max-height: none !important;
        padding-top: $spacer-3;
      }
    }

    & .video-private__preview-image {
      @media (max-width: $screen-xl) {
        border-radius: $border-radius;
      }
    }

    &__icon {
      position: absolute;
      top: 6px;
      left: 6px;
      cursor: pointer;
      z-index: 10;
      @media (max-width: $screen-xl) {
        height: 32px;
        width: 32px;
        top: 14px;
      }
    }
  }
  &__description {
    &__text {
      line-height: 100% !important;
    }
    &__popover {
      width: 38%;
      background-color: transparent;
      @media (max-width: $screen-xs) {
        width: 90%;
      }
      & .ant-popover-arrow {
        display: none;
      }
      & .ant-popover-inner {
        background-color: $pp-color-gray-6;
        border-radius: 20px;
        padding: $spacer-3 $spacer-6;
      }
      & .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
  &__result-table-btn {
    background-color: $pp-color-gold;
    border: none;
    border-radius: 5px;
    padding: 0 $spacer-11;
    display: flex !important;
    align-items: center;
    justify-content: center;
    @media (max-width: $screen-xl) {
      padding: $spacer-5;
      width: 100%;
    }
    & > span {
      @extend .pp-font-title-5;
      line-height: 24px !important;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      @media (max-width: $screen-xl) {
        font-size: 18px !important;
        line-height: 32px !important;
      }
    }
    &:hover, &:active, &:focus {
      background-color: darken($pp-color-gold, 30%);
      border-color: $gray-1;
      & > span {
        color: $gray-1
      }
    }
    &__conateiner {
      padding-right: $spacer-6;
      @media (max-width: $screen-xl) {
        padding-right: 0;
        justify-content: flex-start;
      }
      @media (max-width: $screen-xs) {
        width: 100%;
      }
    }
  }
  &__data {
    max-width: calc(100% - #{$pp-test-item-video-block-width});
    @media (max-width: $screen-xl) {
      max-width: 100%;
    }
    & > div {
      @media (max-width: $screen-xl) {
        border-bottom: $pp-test-item-item-border;
        padding-bottom: $spacer-6;
      }
    }
  }
  &__results {
    &__item {
      box-sizing: border-box;
      padding-bottom: $spacer-1;
      border-bottom: $pp-test-item-item-border;
      margin-left: 0 !important;
      margin-right: 0 !important;
      @media (max-width: $screen-xl) {
        border-bottom: none;
      }
      & > div {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
    }
  }
  &__progress {
    .ant-slider {
      margin: 0;
      touch-action: auto;
      &:hover {
        .ant-slider-rail {
          background-color: $pp-color-black;
        }
      }
    }
    .ant-slider-track {
      background-color: $pp-color-gold !important;
      border-radius: 100px;
      height: 6px;
    }
    .ant-slider-rail {
      height: 6px;
      background-color: $pp-color-black;
      opacity: 0.4;
      border-radius: 100px;
    }
    .ant-slider-handle {
      display: none;
    }
    .ant-slider-step {
      height: 6px;
      .ant-slider-dot {
        border-radius: 100px;
        height: 10px;
        width: 4px;
        background-color: $pp-color-black;
      }
    }
    .ant-slider-mark-text {
      @extend .pp-font-small;
      @media (max-width: $screen-md) {
        font-size: 12px !important;
        line-height: 12px !important;
      }
    }
  }
}
