.player-card {
  & .ant-card-body {
    padding: 0;
  }
  &__avatar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  &__info {
    flex: 1 1 85%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    &__name {
      flex: 1 1 33%;
    }
    &__mobile {
      flex: 1;
    }
  }
  &__favorite {
    &-icon {
      cursor: pointer;
      font-size: $fontSize-lg;
      color: $gray-1
    }
  }
}
