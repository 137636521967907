@import "../base/utiles";

.step-chart-component{
  $chart-height: 6px;
  background: $component-bg-third;
  height: $chart-height;
  min-height: $chart-height;
  &:last-child{
    border-top-right-radius: $spacer-3;
    border-bottom-right-radius: $spacer-3;
  }
  &:first-child{
    border-top-left-radius: $spacer-3;
    border-bottom-left-radius: $spacer-3;
  }
}