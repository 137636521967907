@import "../abstracts/variables";

.component-tag-tabs {
  & .ant-tabs-nav {
    &:before{
      border: none !important;
    }
    &:after{
      border: none !important;
    }
  }
  & .ant-tabs-ink-bar {
    display: none;
  }
  & .ant-tabs-tab {
    background: $component-bg-color;
    border: 1px solid $border-color;
    font-size: $fontSize-xxxxs;
    border-radius: $spacer-8;
    padding: $spacer-2 $spacer-4 !important;
    margin-right: $spacer-2 !important;
    margin-left: $spacer-2 !important;
    overflow: hidden;
    &:hover {
      background: lighten($color-primary, 20%);
    }
    &:active {
      background: lighten($color-primary, 10%);
      color: $gray-11;
    }
    &-active{
      background: $color-primary;
      border: none;
      &:hover {
        background: darken($color-primary, 10%);
        border: none;
      }
      &:active {
        background: darken($color-primary, 20%);
        border: none;
        color: $gray-11;
      }
    }
    &-btn{
      color: $gray-11 !important;
    }
  }
};
