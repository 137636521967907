@import '../abstracts/variables';

.tariffs-component {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 150px;
  & > .ant-radio-button-wrapper {
    @extend .bg-page;
    padding-top: $spacer-5;
    padding-bottom: $spacer-5;
    height: auto;
    border: 1px solid $component-bg-accent !important;
    border-radius: 8px !important;
    &:not(:last-child) {
      margin-bottom: $spacer-5;
    }
    &:not(.ant-radio-button-wrapper-checked) {
      border: 1px solid $page-bg-color !important;
    }
  }
  &__sale-text {
    color: $gray-8 !important;
    position: relative;
    &:before {
      position: absolute;
      border-bottom: 1px solid $red-5;
      content: '';
      width: 100%;
      height: 50%;
      transform: rotate(14deg);
    }
  }
  &__item {
    &__title {
      display: flex;
      align-items: center;
      &__name {
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        max-width: 75%;
        margin-right: $spacer-2;
      }
    }
    &__sale-description {
      width: 100%;
      display: block;
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
}
