@import "../abstracts/variables";

.player-profile-shield {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  &-component {
    position: relative;
  }
  &__avatar {
    border: 1px solid $pp-color-black;
    border-top-left-radius: 8%;
    border-top-right-radius: 8%;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 48%;
    background-color: $gray-1;
    @media (max-width: $screen-md - 1) {
      height: 54%;
    }
    @media (max-width: $screen-sm) {
      height: 53%;
    }
  }
  &__default-avatar {
    border-bottom: 1px solid $pp-color-black;
    padding-top: 6px;
    & > img {
      height: auto;
      width: 40%;
      @media (max-width: $screen-md - 1) {
        width: 54%;
      }
      @media (max-width: $screen-sm) {
        width: 53%;
      }
    }
  }
  &__info {
    padding: 0 $spacer-4;
    @media (max-width: $screen-md - 1) {
      padding: 0 $spacer-6;
    }
    &__name {
      // Change when needed country
      //max-width: 80%;
      max-width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      @media (max-width: $screen-lg + 144) and (min-width: $screen-lg) {
        height: 56px;
      }
      @media (max-width: $screen-md - 1) {
        height: 72px;
      }
      @media (max-width: $screen-xs + 80) {
        height: 52px;
      }
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: $screen-lg + 144) and (min-width: $screen-lg) {
          font-size: 28px;
        }
        @media (max-width: $screen-md - 1) {
          font-size: 36px;
        }
        @media (max-width: $screen-xs + 80) {
          font-size: 26px;
        }
      }
    }
    &__academy-avatar {
      border-radius: 100%;
      object-fit: cover;
      @media (max-width: $screen-lg + 144) and (min-width: $screen-lg) {
        width: 23%;
        height: auto;
      }
      @media (max-width: $screen-md - 1) {
        width: 23%;
        height: auto;
      }
      @media (max-width: $screen-xs) {
        width: 23%;
        height: auto;
      }
    }
    &__group {
      &__name {
        & > div {
          @media (max-width: $screen-md - 1) {
            font-size: 18px;
          }
          @media (max-width: $screen-xs + 80) {
            font-size: 14px;
          }
          @media (max-width: $screen-xs) {
            font-size: 12px;
          }
        }
      }
      &__value {
        & > div {
          @media (max-width: $screen-md - 1) {
            font-size: 52px;
          }
          @media (max-width: $screen-xs + 80) {
            font-size: 40px;
          }
          @media (max-width: $screen-xs) {
            font-size: 34px;
          }
        }
      }
    }
    &__country {
      &__name {
        & > div {
          @media (max-width: $screen-xs) {
            font-size: 8px;
          }
        }
      }
      &__value {
        & > div {
          @media (max-width: $screen-xs) {
            font-size: 14px;
          }
        }
      }
    }
    &__top {
      & > img {
        @media (max-width: $screen-xs) {
          width: 24px;
        }
      }
    }
    &__bottom {
      border-bottom: 1px solid $pp-color-black;
      padding: $spacer-4 0;
      @media (max-width: $screen-xs) {
        padding: $spacer-3 0;
      }
    }
  }
  &__leg-icon {
    width: 36px;
    height: auto;
    @media (max-width: $screen-lg + 144px) and (min-width: $screen-lg) {
      width: 18%;
      height: auto;
    }
    @media (max-width: $screen-md) {
      width: 18%;
      height: auto;
    }
    @media (max-width: $screen-xs) {
      width: 18%;
      height: auto;
    }
  }
  &__logo {
    padding: $spacer-4;
    @media (max-width: $screen-xs) {
      padding: $spacer-4;
    }
  }

  &__tabs {
    & .ant-tabs-nav {
      &:before{
        border: none !important;
      }
      &:after{
        border: none !important;
      }
    }
    & .ant-tabs-ink-bar {
      display: none;
    }
    & .ant-tabs-tab {
      background: $gray-11;
      color: $gray-1;
      font-size: $fontSize-xxxxs;
      border-radius: $spacer-8;
      text-transform: uppercase;
      padding: $spacer-2 $spacer-4 !important;
      margin-right: $spacer-2 !important;
      margin-left: $spacer-2 !important;
      overflow: hidden;
      &:hover {
        background: darken($gray-7, 10%);
        color: $gray-1 !important;
      }
      &:active {
        background: darken($gray-7, 20%);
        color: $gray-1 !important;
        & div{
          color: $gray-1 !important;
        }
      }
      &:focus {
        color: $gray-1 !important;
      }
      &-active{
        background: $gray-7;
        & div{
          color: $gray-1 !important;
        }
      }
    }
  }
}
