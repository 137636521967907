.player-profile {
  &__common-view {
    &__subscription {
      .ant-btn {
        height: 48px;
        font-size: 21px;
      }
    }
    &__skills {
      margin-right: -7px;
    }
  }
  &__skill-view {
    &__skills {
      margin-right: -7px;
    }
  }
}