@import "../abstracts/variables";

.image-private {
  &.radius-default {
    & img {
      @extend .radius-default;
    }
  }
  &.border-circle {
    & img {
      @extend .border-circle;
    }
  }
  & .ant-image {
    width: 100%;
    height: 100%;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover !important;
    }
  }
  &__skeleton {
    & .anticon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      & svg {
        width: 55%;
        height: 55%;
        fill: $gray-6;
      }
    }
  }
}
