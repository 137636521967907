@import "../abstracts/variables";

$promo-color-gray: #686868;

.promo-header {
  background-color: rgba(0, 0, 0, 0.4);
}

.promo-layout {
  background: inherit !important;
}

.promo-background {
  background-image: inherit;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  background-repeat: round;
  @media (min-width: $screen-md) {
    background-image: url("/images/bg.png");
  }
}

.promo-container {
  width: 100%;
  max-width: $promo-screen !important;
  overflow: hidden;
  &__content {
    padding: 40px 8px;
    @media (max-width: $screen-md) {
      padding: 0px 8px;
    }
  }
}

.promo-page{
  &__playerAge {
    font-weight: bold;
    font-size: 20px;
    color: $text-color-bw-white;
    line-height: 18px;
    font-family: Inter, serif;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  &__playerWeakLeg{
    & .ant-rate-star-full, & .ant-rate-star-half{
      color: $text-color-bw-white !important;
    }
    &-label{
      font-family: Inter, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: $text-color-bw-white !important;

      @media (max-width: $screen-md) {
        font-size: 12px;
      }
    }
  }
  &__playerRating {
    background: #FFA800;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    & > label{
      font-family: Inter, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 18px;
      color: $gray-11;
      padding: 2px;
      @media (max-width: $screen-md) {
        font-size: 22px;
      }
    }
  }
  &__playerMetric {
    height: 44px;
    max-height: 44px;
    min-height: 44px;
    padding: 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $page-bg-color;
    border-radius: 8px;
    @media (max-width: $screen-md) {
      height: 32px;
      max-height: 32px;
      min-height: 32px;
    }
    & > label {
      color: $text-color-bw-white;
      font-size: 14px;
      font-family: Inter, serif;
      margin-right: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $screen-md) {
        font-size: 12px;
      }
    }
    &-rating{
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 4px;
    }
  }
  &__playerName {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
    color: $text-color-bw-white;
    @media (max-width: $screen-md) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__image{
    width: 100%;
    height: 256px;
    object-fit: cover;
    @media (max-width: $screen-md) {
      height: 226px;
    }
    &.default-avatar {
      object-position: 0 10%;
    }
  }
  &__player{
    & > .ant-card-body{
      padding: 0 !important;
      overflow: hidden;
    }
  }
  &__card{
    border-radius: 12px !important;
  }
  &__header{
    font-family: Inter, serif;
    font-style: normal;
    font-weight: bold;
    font-size: $fontSize-xxs;
    text-transform: uppercase;
    display: block;
    color: $promo-color-gray;
    margin-bottom: 16px;
  }
  &__academy{
    width: 100px;
    @media (max-width: $screen-md) {
      width: 92px;
    }
  }
  &__academyTitle{
    display: block;
    font-family: Inter, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $promo-color-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__academyImage{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
    @media (max-width: $screen-md) {
      width: 92px;
      height: 92px;
    }
  }
  &__video{
    & .video-react-control-bar{
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, #000000 100%) !important;
    }
  }
};
