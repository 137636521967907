@import "../abstracts/variables";

.trainer-card {
  .ant-card-body,
  .ant-card {
    padding: $spacer-5;
    border-radius: $border-radius;
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &__avatar {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  &__info {
    flex: 1 1 85%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    &__name {
      flex: 1 1 33%;
    }
    &__mobile {
      flex: 1;
    }
  }
}
