@import "../abstracts/variables";

.player-profile-layout {
  & * {
    font-family: 'Object Sans', sans-serif;
  }
  background-color: $gray-1 !important;
  height: 100% !important;

  & .basic__layout__col {
    display: flex !important;
    justify-content: center;
  }
}

@mixin cornerBraces($color) {
  background-image: linear-gradient(0deg, white 0%, white 100%),
  linear-gradient(0deg, $color 0%, $color 100%),
  linear-gradient(0deg, $color 0%, $color 100%),
  linear-gradient(0deg, $color 0%, $color 100%),
  linear-gradient(0deg, $color 0%, $color 100%);
}

$page-padding-top: $spacer-13 * 2 + $spacer-3;
$page-padding-top-mobile: $spacer-13 + $spacer-13 * 0.5;

@media print {
  html {
    width: 210mm;
    height: fit-content;
  }
  #root {
    height: fit-content !important;
  }
  .player-profile-page {
    width: 100% !important;
  }
}

.player-profile-page {
  max-width: 1480px;
  width: 100%;
  height: fit-content;
  padding: $page-padding-top $spacer-13;
  position: relative;
  margin: auto !important;
  @media (max-width: $screen-md) {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding: $page-padding-top-mobile $spacer-0;
  }
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
  &__notification {
    .ant-message-notice-content {
      background-color: $gray-1;
      @extend .pp-font-text;
    }
  }
  &__spinner {
    width: 100%;
    .ant-spin-blur::after {
      opacity: 0;
    }
    &.ant-spin-nested-loading > div > .ant-spin {
      max-height: unset;
      position: fixed;
    }
    .ant-spin-dot-item {
      background-color: $pp-color-gold;
    }
  }
  &__label-icon {
    position: absolute;
    top: -$page-padding-top;
    @media (max-width: $screen-md) {
      top: -$page-padding-top-mobile;
    }
  }
  &__info {
    &__braces {
      padding: $spacer-7 $spacer-9;
      @media (min-width: $screen-sm) {
        @include cornerBraces($pp-color-gold);
      }
      @media (max-width: $screen-xs) {
        padding: 0;
      }
    }
    &__shield {
      margin-bottom: $spacer-9 + $spacer-1 + $spacer-2;
    }
    &__assessment {}
  }
  &__data {
    &__braces {
      padding: $spacer-7 $spacer-9;
      @media (min-width: $screen-sm) {
        @include cornerBraces(#000000);
      }
      @media (max-width: $screen-xs) {
        padding: 0;
      }
    }
    &__recommendations {
      margin-bottom: $spacer-13;
    }
    &__player-development {
      margin-bottom: $spacer-13;
    }
    &__test-results {}
  }
  &__braces {
    border: 2px solid transparent;
    background-origin: padding-box, border-box, border-box, border-box, border-box;
    background-repeat: no-repeat;
    background-size: 100% 100%, 48px 48px, 48px 48px, 48px 48px, 48px 48px;
    background-position: top left, top left, top right, bottom left, bottom right;
  }
}
