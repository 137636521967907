@import '../abstracts/variables';

.flex-noWrap {
  flex-wrap: nowrap;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}