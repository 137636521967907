@import "../abstracts/variables";

.stripe-card-element {
  &__card {
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    &__error {
      border-color: $error-color;
    }
  }
  &__postal-code {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
}