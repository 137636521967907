@import "../abstracts/variables";

.academy-view-page {
  &__tabs {
    .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-content-holder{
      display: none;
    }
    .ant-tabs-tab-active {
      background-color: $component-bg-color;
    }
    &__component {
      min-width: $academy-view-page-tabs-width;
      width: $academy-view-page-tabs-width;
      max-width: $academy-view-page-tabs-width;
      margin-right: $academy-view-page-tabs-margin-right;
    }
  }
  &__content {
    min-width: $academy-view-page-content-width;
    width: $academy-view-page-content-width;
    max-width: $academy-view-page-content-width;
  }
  &.mobile {
    width: 100%;
    .academy-view-page__tabs, .academy-view-page__tabs__component {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      margin-right: $spacer-0;
    }
    .academy-view-page__content {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
  &__trainers, &__teams {
    &__list {
      li {
        @extend .cursor-pointer;
        border: none !important;
        padding: 0 !important;
        margin-bottom: $spacer-3;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .ant-list-empty-text {
    padding: $spacer-0;
  }
}
