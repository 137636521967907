@import "../abstracts/variables";

.user-edit-page {
  &__block {
    background-color: $component-bg-color;
    border-radius: $border-radius;
    &__data {
      flex: 1;
      & .ant-form-item {
        flex: 1;
        margin-bottom: 0;
      }
    }
    &-left-empty {
      padding-right: $spacer-5;
      padding-left: $spacer-5;
      &-desktop {
        padding-right: 45%;
        padding-left: $spacer-11 * 3;
      }
    }
  }
}
