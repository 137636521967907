@import "../abstracts/variables";

$date-column-width: 28%;
$description-column-width: 45%;
$amount-column-width: 14%;
$status-column-width: 13%;
$date-column-width-mobile: 25%;
$description-column-width-mobile: 28%;
$amount-column-width-mobile: 22%;
$status-column-width-mobile: 25%;

.payment-and-subscription-page {
  @media (max-width: $screen-lg) {
    margin: 0 $spacer-5;
  }
  & .ant-tabs-nav {
    margin-bottom: $spacer-0;
    &:before {
      display: none;
    }
  }
  & .ant-tabs-top {
    & .ant-tabs-tab {
      padding: $spacer-4 0;
      margin-right: $spacer-9;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__subscriptions-tab {
    &.mobile {
      margin-left: -$spacer-5;
      margin-right: -$spacer-5;
    }
    &__list {
      &__element {
        cursor: pointer;
        margin-bottom: $spacer-5;
        &:last-child {
          margin-bottom: $spacer-0;
        }
        &__data {
          max-width: 78%;
          @media (max-width: $screen-lg) {
            max-width: 85%;
          }
          &__info {
            max-width: 88%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
          }
        }
      }
    }
  }
  &__payments-tab {
    padding: $spacer-5;
    @media (max-width: $screen-lg) {
      padding: $spacer-0;
      margin-left: -$spacer-5;
      margin-right: -$spacer-5;
    }
    &__header {
      background-color: $page-bg-color;
      padding: $spacer-5 0;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      & > .ant-typography {
        padding-left: $spacer-5;
        @media (max-width: $screen-lg) {
          padding-left: $spacer-3;
        }
      }
      &__date {
        width: $date-column-width;
        @media (max-width: $screen-lg) {
          width: $date-column-width-mobile;
        }
      }
      &__description {
        width: $description-column-width;
        @media (max-width: $screen-lg) {
          width: $description-column-width-mobile;
        }
      }
      &__amount {
        width: $amount-column-width;
        @media (max-width: $screen-lg) {
          width: $amount-column-width-mobile;
        }
      }
      &__status {
        width: $status-column-width;
        @media (max-width: $screen-lg) {
          width: $status-column-width-mobile;
        }
      }
    }
    &__list {
      & > .ant-typography {
        border-bottom: 1px solid $gray-9;
        padding-left: $spacer-5;
        @media (max-width: $screen-lg) {
          padding-left: $spacer-3;
        }
      }
      &__element {
        &__date {
          width: $date-column-width;
          @media (max-width: $screen-lg) {
            width: $date-column-width-mobile;
          }
        }
        &__description {
          width: $description-column-width;
          @media (max-width: $screen-lg) {
            width: $description-column-width-mobile;
          }
        }
        &__amount {
          width: $amount-column-width;
          @media (max-width: $screen-lg) {
            width: $amount-column-width-mobile;
          }
        }
        &__status {
          width: $status-column-width;
          @media (max-width: $screen-lg) {
            width: $status-column-width-mobile;
          }
        }
      }
    }
  }
}
