@import "../abstracts/variables";

.restore-password-page{
  min-width: $common-page-content-width;
  width: $common-page-content-width;
  max-width: $common-page-content-width;
  background-color: $component-bg-color;
  border-radius: $border-radius;
  @media (max-width: $screen-lg) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
};
