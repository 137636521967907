@import "../abstracts/variables";

.root-suspense-fallback {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  &.white-style {
    background-color: $gray-1;
    .ant-spin-dot-item {
      background-color: $pp-color-gold;
    }
  }
}
