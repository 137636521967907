@import '../abstracts/variables';

$rating-border-color: rgba(47, 35, 2, 0.14);

@font-face {
  font-family: 'Bebas Neue Cyrillic';
  src: url('/icons/for-preview/Bebas_Neue.ttf'); /* Путь к файлу со шрифтом */
}

.rating-layout {
  background-color: $gray-12 !important;
  padding-top: $header-height;
  height: 100% !important;
}

.bold-montserrat-dropdown-menu {
  & strong {
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.sorter-icon-style {
  content: '';
  position: absolute;
  left: 0;
  margin-top: $spacer-1;
  width: 9px;
  height: 9px;
  display: inline-block;
  background-image: url('../../icons/sorter.png');
}

.rating-header {
  background-color: rgba(0, 0, 0, 0.7);
  &__right {
    @media (max-width: $promo-screen) {
      padding-right: $spacer-4;
      text-align: end;
    }
    @media (max-width: $screen-xs) {
      padding-right: $spacer-0;
    }
  }
  &__dropdown {
    @media (max-width: $screen-xs) {
      width: 100vw;
      top: 0 !important;
    }
    & .ant-dropdown-menu {
      background-color: $gray-13;
      padding-bottom: $spacer-7;
      & > .ant-dropdown-menu-item {
        padding: $spacer-5 $spacer-9;
        &:first-of-type {
          padding-top: $spacer-0;
        }
      }
    }
    & .rating-header__menu__element {
      @extend .bold-montserrat-dropdown-menu;
    }
    & .rating-header__login {
      background-color: $gray-11;
      margin: 0;
      display: inline-block;
      padding-left: $spacer-3;
      padding-right: $spacer-4;
      @extend .bold-montserrat-dropdown-menu;
    }
    & .rating-header__social {
      margin-left: 0;
    }
    & .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: transparent;
    }
    &__close {
      width: 100%;
      text-align: end;
      padding-right: $spacer-5;
      & .anticon-close {
        color: #505050;
      }
    }
  }
  &__elements {
    display: flex;
    align-items: center;
  }
  &__splitter {
    width: 1px;
    height: 16px;
    background: $gray-10;
  }
  &__menu {
    width: fit-content;
    display: flex;
    align-items: center;
    &__element {
      margin-right: $spacer-6;
    }
  }
  &__login {
    padding: $spacer-3 $spacer-3 + 2;
    background: rgba(0, 0, 0, 0.14);
    border-radius: $border-radius * 2;
    margin: 0 $spacer-6;
    &:hover {
      -webkit-box-shadow: 0px 0px 6px 4px rgba(138, 138, 145, 0.4);
      -moz-box-shadow: 0px 0px 6px 4px rgba(138, 138, 145, 0.4);
      box-shadow: 0px 0px 6px 4px rgba(138, 138, 145, 0.4);
    }
    & > a {
      display: flex;
      align-items: center;
      & > img {
        margin-right: $spacer-2;
      }
    }
  }
  &__social {
    margin-left: $spacer-6;
    & a:not(:last-child) {
      margin-right: $spacer-5;
    }
  }
}

.rating-container {
  width: 100%;
  max-width: $promo-screen !important;
  font-family: 'Montserrat', sans-serif;
  & .ant-typography {
    font-family: 'Montserrat', sans-serif !important;
    & > strong {
      font-family: 'Montserrat', sans-serif;
    }
  }
  &__content {
    padding: 40px 8px;
    @media (max-width: $screen-md) {
      padding: 0 8px;
    }
  }
}

.rating-page {
  @media (max-width: $screen-lg) {
    padding: 0 $spacer-5;
  }
  display: flex;
  flex-flow: column nowrap;
  &__player-card-open {
    &:hover {
      .rating-page__player-item__person-data {
        box-shadow: inset 0 0 100px 12px rgba(242, 189, 0, 0.4), 0 0 50px 20px rgba(242, 189, 0, 0.4);
      }
    }
  }
  &__ask-question {
    bottom: 10%;
    left: 100%;
    position: sticky;
    z-index: 100;
    cursor: pointer;
    width: fit-content;
    & .rating-page__ask-question-person {
      display: inherit;
      opacity: 1;
      width: 100%;
    }
    &-icon {
      border-radius: 100px;
      font-size: 24px;
      fill: white;
    }
    &-container {
      width: fit-content;
      margin-left: auto;
      padding: 16px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1000px;
      background-color: #18b853;
    }
    &-support {
      display: flex;
      opacity: 1;
      padding: 12px 16px;
      border-style: solid;
      border-width: 1px;
      border-color: #818181;
      background-color: #1e1f21;
      transition: opacity 200ms ease-in-out;
      color: #fff;
      text-decoration: none;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;
      width: 100%;
      border-radius: 1000px;
      &:hover {
        background-color: darken(#1e1f21, 10%);
      }
      &-logo {
        border-radius: 1000px;
      }
      &-name {
        margin-left: 16px;
        color: #fff;
      }
    }
    &-title {
      background-color: #18b853;
      padding-left: 16px;
    }
    &-hidden {
      display: none;
      opacity: 0;
      width: 0;
    }
  }
  &__banners {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -$header-height;
  }
  &__data {
    z-index: 1;
    .ant-list-empty-text {
      padding: 0;
    }
  }
  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    &.banner-1 {
      width: 100%;
      height: 970px;
      background: url('/images/rating-banner-1.png') no-repeat center top;
      @media (max-width: $screen-xs) {
        height: 328px;
        background: url('/images/rating-banner-mobile.png') no-repeat center top;
      }
    }
    &.banner-2 {
      width: 100%;
      height: 1198px;
      background: url('/images/rating-banner-2.png') no-repeat center top;
      opacity: 0.8;
      @media (max-width: $screen-xs) {
        display: none;
      }
    }
  }
  &__info {
    padding-top: 69px;
    margin-bottom: $spacer-9;
    @media (max-width: $screen-lg) {
      padding-top: $spacer-11;
      margin-bottom: $spacer-4;
    }
    @media (max-width: $screen-md) {
      padding-top: $spacer-9;
      margin-bottom: $spacer-0;
    }
    @media (max-width: $screen-xs) {
      padding-top: $spacer-3;
    }
    &__title {
      width: 60%;
      font-size: 48px !important;
      line-height: 56px !important;
      font-family: 'Bebas Neue Cyrillic', sans-serif !important;
      color: $gray-1;
      z-index: 1;
      @media (max-width: $screen-lg) {
        width: 90%;
      }
      @media (max-width: $screen-sm) {
        width: 100%;
        font-size: 46px !important;
        line-height: 54px !important;
      }
      @media (max-width: $screen-xs) {
        font-size: 24px !important;
        line-height: 26px !important;
        width: 90%;
      }
    }
  }
  & .ant-list {
    min-width: 100%;
    .ant-spin-container {
      min-width: 100%;
      & > .ant-row {
        min-width: 100%;
      }
    }
  }
  &__apps {
    padding-bottom: 76px;
    margin-top: $spacer-11;
    @media (max-width: $screen-lg) {
      padding-bottom: 200px;
    }
  }
  &__filters-block {
    margin-bottom: $spacer-9 * 2;
    @media (max-width: $screen-xs) {
      margin-bottom: $spacer-9;
    }
  }
  &__age-filter {
    font-family: 'Montserrat', sans-serif;
    &__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: $gray-6;
      margin-right: $spacer-7;
      @media (max-width: 960px) {
        margin-bottom: $spacer-3;
      }
    }
    & .ant-checkbox-group {
      display: flex;
    }
    & .ant-checkbox-wrapper {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-left: $spacer-3;
      @media (max-width: $screen-lg) {
        width: 62px;
        margin-bottom: $spacer-2;
      }
      & .ant-checkbox-inner {
        &::after {
          border-color: $gray-13;
        }
      }
      & > span:last-child {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: $gray-7;
        font-family: 'Montserrat', sans-serif;
        display: inline-block;
      }
      & .ant-checkbox-inner {
        background: $gray-11;
        border: 1px solid $gray-7;
        border-radius: 4px;
      }
      &.ant-checkbox-wrapper-checked {
        & > span:last-child {
          color: $gray-1;
        }
        & .ant-checkbox-inner {
          background-color: $gray-1;
        }
      }
    }
    &.mobile {
      .rating-page__age-filter__label {
        margin-bottom: $spacer-6;
      }
      .ant-checkbox-group {
        margin-bottom: $spacer-3;
      }
      .ant-checkbox-wrapper {
        width: 25%;
        margin-left: 0;
        margin-bottom: $spacer-8;
      }
    }
  }
  &__sorters {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-6;
    & > span:first-child {
      margin-right: $spacer-10;
    }
    &__sort-by {
      cursor: pointer;
      color: $gray-7;
      font-weight: 600;
      padding-left: $spacer-5;
      position: relative;
      display: flex;
      align-items: center;
      &.active {
        color: $gray-1;
        &.ASC {
          &:before {
            @extend .sorter-icon-style;
            transform: rotate(180deg) scale(-1, 1);
          }
        }
        &.DESC {
          &:before {
            @extend .sorter-icon-style;
          }
        }
      }
      &.mobile {
        padding-left: $spacer-0;
      }
    }
    &__mobile-indicator {
      position: relative;
      &__order-direction {
        width: 32px;
        height: 32px;
        padding: $spacer-3;
        margin-right: $spacer-3;
        background: $color-black-shadow;
        border-radius: $border-radius;
        &__icon {
          width: 16px !important;
          height: 14px !important;
          background-repeat: no-repeat;
          position: relative !important;
          background-size: 16px 14px;
          &.ASC {
            @extend .sorter-icon-style;
            transform: rotate(180deg) scale(-1, 1);
          }
          &.DESC {
            @extend .sorter-icon-style;
          }
        }
      }
    }
    &.mobile {
      flex-wrap: nowrap;
      & > span:first-child {
        margin-right: $spacer-5;
      }
    }
  }
  &__filter-indicator {
    background: $color-black-shadow;
    border-radius: $border-radius;
    padding: $spacer-2 $spacer-3;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    &__icon {
      width: 24px;
      height: 24px;
      background-image: url('../../icons/filter-icon.png');
      background-repeat: no-repeat;
      margin-right: $spacer-2;
    }
    &__name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray-6;
      font-family: 'Montserrat', sans-serif;
    }
    &__indicator {
      width: 6px;
      height: 6px;
      border-radius: 100px;
      align-self: flex-start;
      &.has-filters {
        background-color: $color-gold;
      }
    }
  }
  &__filter-apply {
    width: 85%;
    border-radius: 16px;
    padding: $spacer-7 0;
    background-color: $gray-11;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: $spacer-13 + $spacer-3 auto $spacer-11 auto;
  }
  &__base-modal {
    & .ant-modal-content {
      background-color: $gray-1;
      border-radius: 0;
      & .ant-modal-close-icon {
        color: $gray-6;
      }
      & .ant-modal-header {
        background-color: inherit;
      }
      & .ant-modal-body {
        color: $gray-10;
        padding: $spacer-5 100px 70px 100px;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        font-family: 'Montserrat', sans-serif;
        display: flex;
        flex-flow: column nowrap;
        @media (max-width: $screen-sm) {
          font-size: 18px;
          line-height: 24px;
          padding: $spacer-5 50px 30px 50px;
        }
        @media (max-width: $screen-xs) {
          font-size: 14px;
          line-height: 20px;
          padding: $spacer-5 25px 20px 25px;
        }
        & a {
          color: $color-link;
        }
        & span {
          font-family: 'Montserrat', sans-serif;
        }
        & .ant-btn-primary {
          background-color: $color-gold;
          border-radius: 14px;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: $gray-13;
          padding-top: $spacer-6;
          padding-bottom: $spacer-6;
          height: auto;
          border-color: $color-gold;
          @media (max-width: $screen-sm) {
            font-size: 16px;
            line-height: 22px;
          }
          @media (max-width: $screen-xs) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  &__filter-modal {
    margin: 0;
    max-width: 100vw;
    bottom: 0;
    position: absolute;
    padding-bottom: 0;
    & .ant-modal-content {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-radius: 0;
      background-color: $gray-13;
      & .ant-modal-close {
        display: none;
      }
    }
  }
  &__player-item {
    position: relative;
    width: 100%;
    height: 100%;
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      &.hidden {
        mix-blend-mode: luminosity;
        opacity: 0.2;
      }
    }
    &__avatar {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 65%;
      background-size: cover;
      -webkit-mask-image: url(/icons/for-preview/shield-base.svg);
      mask-image: url(/icons/for-preview/shield-base.svg);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 100% auto;
      mask-size: 100% auto;
    }
    &__shield-icon {
      width: 100%;
      height: auto;
      min-height: 200px;
      z-index: 2;
    }
    &__open-card {
      &-container {
        z-index: 20;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      width: min-content;
      padding: $spacer-5;
      color: $gray-1;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      cursor: pointer;
      border-radius: 16px;
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.27);
      font-family: 'Montserrat', sans-serif;
      &:hover {
        -webkit-box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.4);
        -moz-box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.4);
        box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.4);
      }
    }
    &__content {
      z-index: 3;
      position: absolute;
      top: 0;
      padding: $spacer-8 $spacer-5 $spacer-5 $spacer-5;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      &__top {
        min-height: 49%;
        height: 49%;
        max-height: 49%;
        @media (max-width: $screen-xs) {
          min-height: 46%;
          height: 46%;
          max-height: 46%;
        }
      }
      &__bottom {
        height: 50%;
      }
    }
    &__rating {
      width: 50px;
      @media (max-width: $screen-xs) {
        width: 38px;
      }
      &__rating__top,
      &__rating__bottom {
        width: 100%;
      }
      &__top {
        height: 50px;
        background-color: $color-gold;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        color: $green-10;
        @media (max-width: $screen-xs) {
          height: 38px;
        }
        &__value {
          font-size: 30px;
          line-height: 34px;
          font-weight: 900;
          letter-spacing: -0.03px;
          font-family: 'Inter', sans-serif;
          @media (max-width: $screen-xs) {
            font-size: 24px;
            line-height: 28px;
          }
        }
        &__label {
          font-size: 8px;
          line-height: 10px;
          font-weight: 600;
          letter-spacing: 0.57px;
          font-family: 'Inter', sans-serif;
          @media (max-width: $screen-xs) {
            font-size: 5px;
            line-height: 5px;
            letter-spacing: 0.44px;
          }
        }
      }
      &__bottom {
        height: 26px;
        background: rgba(0, 0, 0, 0.56);
        border-radius: 0 0 $border-radius $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: $screen-xs) {
          height: 20px;
        }
        &__group {
          font-size: 16px;
          line-height: 20px;
          font-weight: 800;
          letter-spacing: -0.03px;
          color: $gray-1;
          font-family: 'Inter', sans-serif;
          @media (max-width: $screen-xs) {
            font-size: 13px;
            line-height: 17px;
          }
        }
      }
    }
    &__person-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $spacer-6;
      @media (max-width: $screen-lg) {
        margin-bottom: $spacer-4;
      }
      @media (max-width: $screen-xs) {
        margin-bottom: $spacer-3;
      }
      &__name {
        width: 73%;
        min-width: 73%;
        max-width: 73%;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        & > span {
          font-family: 'Bebas Neue Cyrillic', sans-serif !important;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 100%;
          font-weight: 400;
          letter-spacing: 0.37px;
          color: $gold-10;
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          @media (max-width: $screen-xs) {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
    }
    &__person-data {
      display: flex;
      flex-flow: row wrap;
      & > div {
        width: 50%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
      &__0 {
        border-right: 1px solid $rating-border-color;
        border-bottom: 1px solid $rating-border-color;
        padding-bottom: $spacer-3;
        @media (max-width: $screen-xs) {
          padding-bottom: $spacer-2;
        }
      }
      &__1 {
        border-bottom: 1px solid $rating-border-color;
        padding-bottom: $spacer-3;
        padding-left: $spacer-3;
        @media (max-width: $screen-xs) {
          padding-bottom: $spacer-2;
          padding-left: $spacer-2;
        }
      }
      &__2 {
        border-right: 1px solid $rating-border-color;
        padding-top: $spacer-3;
        @media (max-width: $screen-xs) {
          padding-top: $spacer-2;
        }
      }
      &__3 {
        padding-top: $spacer-3;
        padding-left: $spacer-3;
        @media (max-width: $screen-xs) {
          padding-top: $spacer-2;
          padding-left: $spacer-2;
        }
      }
      &__value {
        font-size: 32px;
        line-height: 32px;
        min-width: 30%;
        width: 30%;
        max-width: 30%;
        text-align: end;
        letter-spacing: -0.02px;
        color: $gold-10;
        margin-right: $spacer-2;
        margin-top: -$spacer-2;
        font-family: 'Bebas Neue Cyrillic', sans-serif !important;
        &.big {
          margin-left: -$spacer-2;
          min-width: 40%;
          width: 40%;
          max-width: 40%;
        }
        @media (max-width: $screen-lg) {
          font-size: 28px;
          line-height: 28px;
        }
        @media (max-width: $screen-xs) {
          font-size: 24px;
          line-height: 24px;
          margin-top: -$spacer-1;
          &.big {
            min-width: 41%;
            width: 41%;
            max-width: 41%;
          }
        }
        @media (max-width: $screen-xxs) {
          font-size: 20px;
          line-height: 20px;
        }
      }
      &__label {
        font-size: 11px;
        line-height: 12px;
        letter-spacing: -0.02px;
        color: $gold-10;
        font-family: 'Bebas Neue Cyrillic', sans-serif !important;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        @media (max-width: $screen-lg) {
          font-size: 10px;
          line-height: 11px;
        }
        @media (max-width: $screen-xs) {
          font-size: 9px;
          line-height: 10px;
        }
        @media (max-width: $screen-xxs) {
          font-size: 8px;
          line-height: 9px;
        }
      }
    }
    &__logo {
      display: flex;
      justify-content: center;
      margin-top: $spacer-3;
      @media (max-width: $screen-xs) {
        margin-top: $spacer-2;
      }
    }
    &.skeleton {
      .ant-skeleton {
        .ant-skeleton-title {
          margin: 0;
        }
      }
      @media (max-width: $screen-xs) {
        .ant-skeleton-title {
          height: 12px;
        }
      }
    }
  }
}
