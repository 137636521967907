@import "colors";

// Color variables
$color-gray-04: rgba(224, 224, 224, 0.4);
$color-true-white-065: rgba(255, 255, 255, 0.65);
$color-true-black-018: rgba(0, 0, 0, 0.18);
$color-true-black-04: rgba(0, 0, 0, 0.4);
$color-true-black-06: rgba(0, 0, 0, 0.6);
$color-true-black-085: rgba(0, 0, 0, 0.85);
$color-black-shadow: rgba(0, 0, 0, 0.34);

$color-red: $red-6;
$color-gold: $gold-6;
$color-orange: $orange-6;
$color-danger: $red-6;
$color-green: $green-6;
$color-blue: $blue-6;
$color-link: $yellow-6;
$color-yellow: $yellow-5;
$error-color: $red-8;

// night theme
//$color-primary: $color-blue;
// night theme
$color-primary: $color-yellow;
$color-alternative: $color-blue;
$color-secondary: $color-true-black-06;

// BG
// night theme
//$component-bg-color: $gray-12; // bg-primary
// night theme
$component-bg-color: $gray-1; // bg-primary
$page-bg-color: $gray-3;
$component-bg-accent: $color-yellow;
$component-bg-secondary: $color-gray-04;
$component-bg-third: $gray-5;
$component-bg-fourth: $gray-7;

// Text
// day theme
$text-color-light: $gray-1;
$text-color-primary: $gray-13;
$text-color-accent: $color-yellow;
$text-color-disable: $color-true-black-04;
$text-color-secondary: $color-true-black-06;
$text-color-bw-white: $gray-12;
$text-color-additional: $color-blue;
$text-color-danger: $color-red;
$text-color-additional-3: $gold-5;
$text-color-additional-4: $cyan-5;

// Input
// day theme
$input-color-bg: $gray-2;

// Border
// day theme
$border-light-color: $gray-4;
$border-color: $gray-6;

// Icon
// day theme
$icon-color-accent: $color-yellow;
$icon-color-disabled: $color-true-black-04;
$icon-color-secondary: $color-true-black-06;
$icon-color-bw-white: $gray-12;
$icon-color-primary: #000000;
$icon-color-additional: $color-blue;
$icon-color-additional-2: $color-blue;
$icon-color-additional-5: $blue-5;

// Circumstantial
// day theme
$popup-color-bg: $gray-4;
$color-layout: $gray-2;
$color-shadow: #00000026;
$color-card: $gray-12;

$color-character-disabled-amp-placeholder-25: rgba(0, 0, 0, 0.25);

// Player profile colors
$pp-color-gold: $color-yellow;
$pp-color-black: #000000;
$pp-color-gray-1: #999999;
$pp-color-gray-2: #e6e6e6;
$pp-color-gray-3: #f5f5f5;
$pp-color-gray-4: #505152;
$pp-color-gray-5: #c4c4c4;
$pp-color-gray-6: #f3f3f3;
$pp-color-green: #5ec58e;
$pp-color-green-dark: #52B603;
$pp-color-green-lite: #52B60345;

// Gradients
$icon-bg-gradient: linear-gradient(0deg, #5558ff 0%, #00c0ff 100%);

$promo-screen: 1170px;
$layout-medium-width: 1064px;

// Screen size variables
$screen-xxs: 320px;
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md-break: 767px;
$screen-md: 768px;
$screen-lg: 856px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

$screens: (
  xs: $screen-xs,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
);

// Box shadows
$box-shadow-1: 0 1px 2px rgba(0, 0, 0, 0.06);
$box-shadow-2: 0 1px 3px rgba(0, 0, 0, 0.1);

// Spaces
$spacer: 8px;

$spacer-0: $spacer * 0;
$spacer-1: $spacer / 4;
$spacer-2: $spacer / 2;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 2;
$spacer-6: $spacer * 2.5;
$spacer-7: $spacer * 3;
$spacer-8: $spacer * 3.5;
$spacer-9: $spacer * 4;
$spacer-10: $spacer * 4.5;
$spacer-11: $spacer * 5;
$spacer-12: $spacer * 5.5;
$spacer-13: $spacer * 6;

// Font size variables
$fontSize-xxxxxs: 8px;
$fontSize-xxxxs: 10px;
$fontSize-xxxs: 12px;
$fontSize-xxs: 14px;
$fontSize-xs: 16px;
$fontSize-sm: 18px;
$fontSize-md: 20px;
$fontSize-lg: 24px;
$fontSize-xlg: 26px;
$fontSize-xxslg: 30px;
$fontSize-xxlg: 36px;
$fontSize-xxxlg: 48px;

$fontSizes: (
  xxxs: 12px,
  xxs: 14px,
  xs: 16px,
  sm: 18px,
  md: 20px,
  lg: 24px,
  xlg: 26px,
  xxslg: 30px,
  xxlg: 36px,
  xxxlg: 48px
);

$font-main: 'Golos UI';
$font-secondary: 'Golos';
$font-backup: sans-serif;

// Header
$header-height: 65px;
$header-logo-height: 30px;

// Font weight
$fontWeight-xxl: 900;

// Modal window
$modalWidth: 350px;

// Border radius
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius: 6px;
$border-radius-content: 8px;
$border-radius-tag: 10px;

// Antd button height
$button-large: 40px;

// Header
$logo-img-sm: 48px;
$logo-img-xs: 32px;

// Mentor-list
$icon-empty-list-size: 128px;

// Sizes
$max-content-width: 1280px;

// Center with logo Layout
$common-page-content-width: 334px;

// Service Documents page
$service-documents-page-content-width: 283px;

//Badge
$badge-size: 12px;

// Player page
$player-page-tabs-width: 180px;
$player-page-tabs-margin-right: $spacer-5;
$player-page-content-width: calc(100% - #{$player-page-tabs-width} - #{$player-page-tabs-margin-right});
$player-shield-width: 264px;
$player-shield-height: 426px;

// Franchise Academy page
$academy-view-page-tabs-width: 200px;
$academy-view-page-tabs-margin-right: $spacer-5;
$academy-view-page-content-width: calc(100% - #{$academy-view-page-tabs-width} - #{$academy-view-page-tabs-margin-right});
