@import '../abstracts/variables';

.auth-page {
  min-width: $common-page-content-width;
  width: $common-page-content-width;
  max-width: $common-page-content-width;
  @media (max-width: $screen-lg) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  &__form-wrapper {
    background-color: $component-bg-color;
    border-radius: $border-radius;
  }
  & .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  & .ant-tabs-nav-list {
    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      border-bottom: 1px solid $gray-10;
    }
  }
}
