@import "../abstracts/variables";

.sign-up-success-page {
  min-width: $common-page-content-width;
  width: $common-page-content-width;
  max-width: $common-page-content-width;
  @media (max-width: $screen-lg) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
};
