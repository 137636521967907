@import "../abstracts/variables";

.player-test-result-average {
  &__tooltip {

  }
  &__results {
    &__item {
      box-sizing: border-box;
      margin-left: 0 !important;
      margin-right: 0 !important;
      @media (max-width: $screen-xl) {
        border-bottom: none;
      }
      & > div {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      &__title {
        min-width: 0;
      }
    }
  }
  &__progress {
    .ant-slider {
      margin: 0;
      touch-action: auto;
      &:hover {
        .ant-slider-rail {
          background-color: $gray-13;
        }
      }
    }
    .ant-slider-track {
      background-color: $color-yellow !important;
      border-radius: 100px;
      height: 6px;
    }
    .ant-slider-rail {
      height: 6px;
      background-color: $gray-13;
      opacity: 0.4;
      border-radius: 100px;
    }
    .ant-slider-handle {
      display: none;
    }
    .ant-slider-step {
      height: 6px;
      .ant-slider-dot {
        border-radius: 100px;
        height: 10px;
        width: 4px;
        border: 2px solid $border-color;
      }
    }
    .ant-slider-mark-text {
      font-weight: 400;
      font-style: normal;
      font-size: $fontSize-xxxxxs;
      line-height: 100%;
      color: $text-color-primary;
      padding-right: $spacer-3;
    }
  }
}
