@import "../abstracts/variables";

.payment-check-modal {
  & .ant-modal-content {
    background-color: $component-bg-color;
  }
  &__description {
    word-break: break-word;
    white-space: pre-line;
    & a.ant-typography {
      text-decoration: none;
    }
  }
}
