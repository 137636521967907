@import "../abstracts/variables";

$mentor-player-page-width: $common-page-content-width + 250px;

.mentor-player-invite-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    min-width: $mentor-player-page-width;
    width: $mentor-player-page-width;
    max-width: $mentor-player-page-width;
    background-color: $component-bg-color;
    border-radius: $border-radius;
    @media (max-width: $screen-lg) {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
};
