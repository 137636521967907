@import '../abstracts/variables';

.teams-badge {
  width: 100%;
  &__element {
    display: flex;
    padding: $spacer-2 $spacer-3;
    margin: 0 $spacer-1;
    text-align: center;
    background: $page-bg-color;
    border: 1px solid $border-color;
    color: $color-secondary !important;
    font-size: $fontSize-xxxs;
    border-radius: $border-radius;
  }
}
