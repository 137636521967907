.player-card-full {
  &:hover, &:active {
    @extend .box-shadowed;
  }
  .ant-card-body {
    padding: 0;
  }
  &__name {
    min-width: 44%;
  }
}