@import "../abstracts/variables";

.radar-chart{
  & svg{
    overflow: visible !important;
  }
  &_draft{
    & svg{
      overflow: visible !important;
    }
    &__container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: column;
    }
    &__label{
      color: $gray-11;
      padding: $spacer-2 $spacer-4;
      border: 1px solid;
      width: fit-content;
      border-radius: $border-radius;
      text-transform: uppercase;
      font-size: $fontSize-lg;
      background: white;
      font-weight: 400;
      position: absolute;
      margin: auto;
      z-index: 1;
    }
  }
}
