@import "../abstracts/variables";

.player-profile-not-found-layout {
  & * {
    font-family: 'Object Sans', sans-serif;
  }
  background: url('/images/pp-not-found-bg.jpg') no-repeat center top !important;
  background-size: cover !important;
  height: 100% !important;
  & .basic__layout__col {
    display: flex !important;
    justify-content: center;
  }
}


.player-profile-not-found-page {
  width: 100%;
  position: relative;
  &__title {
    color: $gray-1;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 51px;
    text-transform: uppercase;
    @media (max-width: $screen-md) {
      font-size: 40px;
      line-height: 41px;
    }
    @media (max-width: $screen-xs) {
      font-size: 30px;
      line-height: 31px;
    }
  }
  &__description {
    color: $gray-1;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    text-transform: uppercase;
    @media (max-width: $screen-md) {
      font-size: 30px;
      line-height: 31px;
    }
    @media (max-width: $screen-xs) {
      font-size: 20px;
      line-height: 21px;
    }
  }
  &__icon {
    width: 21%;
    height: auto;
    @media (max-width: $screen-md) {
      width: 40%;
    }
  }
  &__logo {
    position: absolute;
    top: 0;
    left: 2%;
  }
}
