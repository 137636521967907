@import "../abstracts/variables";

$player-avatar-width: 68%;
$player-shield-logo-max-height: 51px;

.player-shield {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-content: flex-start;
  flex-flow: column nowrap;
  &-component {
    position: relative;
    width: $player-shield-width;
    min-width: $player-shield-width;
    max-width: $player-shield-width;
    height: $player-shield-height;
  }
  &__top {
    width: 100%;
    height: 48%;
    position: relative;
    &__info {
      width: 100%;
      height: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      padding: $spacer-1 - 1 $spacer-1 0 $spacer-1 - 1;
      border-top-right-radius: 10%;
      overflow: hidden;
      &__wrapper {
        position: relative;
        height: 100%;
        & > * {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &__block-wrapper {
        width: 100% - $player-avatar-width;
      }
      &__group {
        z-index: 0;
        height: 50%;
        align-items: flex-end;
        border-radius: 20px 0 0 20px;
        padding-bottom: $spacer-5;
        div {
          color: $gray-1 !important;
        }
      }
      &__rating {
        z-index: 1;
        height: 30%;
        padding-top: $spacer-3;
        border-radius: 21px 20px 0 20px;
        &-empty {
          margin-top: -$spacer-2;
        }
      }
      &__additional {
        top: unset;
        bottom: 0;
        width: 100% - $player-avatar-width;
      }
    }
    &__icon {
      padding: 1px 1px 0 0;
      width: $player-avatar-width;
      border: 1px solid $pp-color-black;
      border-top-width: 0;
      border-right-width: 0;
      border-top-left-radius: 10%;
      border-bottom-left-radius: 10%;
      border-top-right-radius: 11%;
      & > * {
        border-top-left-radius: 9%;
        border-bottom-left-radius: 9%;
        border-top-right-radius: 11%;
      }
      &__avatar {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        background-color: $gray-1;
      }
      &__default-avatar {
        height: 100%;
        background-color: $component-bg-color;
      }
    }
  }
  &__skills {
    padding: 0 $spacer-4;
    &__wrapper {
      border-top: 1px solid $gray-11;
      padding-top: $spacer-3;
    }
    &__divider {
      width: 1px;
      height: 100%;
      background-color: $gray-11;
    }
  }
  &__info {
    padding: 0 $spacer-4;
    &__name {
      max-width: 81%;
      height: 48px;
      display: flex;
      align-items: center;
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__academy-avatar {
    border-radius: 100%;
    object-fit: cover;
    margin-top: $spacer-5;
  }
  &__leg-icon {
    width: 36px;
    height: auto;
  }
  &__logo {
    padding: $spacer-5 $spacer-3;
    max-height: $player-shield-logo-max-height;
    a {
      height: 100%;
    }
    &__link {
      &_disabled {
        cursor: default;
        pointer-events: none;
      }
    }
    img {
      max-width: 100%;
      width: auto;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }

    &.custom-logo {
      padding: $spacer-0 $spacer-3 $spacer-2 $spacer-3;
    }
  }
}
