@import "../abstracts/variables";

.service-documents-page {
  min-width: $service-documents-page-content-width;
  width: $service-documents-page-content-width;
  max-width: $service-documents-page-content-width;
  background-color: $component-bg-color;
  border-radius: $border-radius;
  @media (max-width: $screen-lg) {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
  }
  &__doc {
    &:not(:last-child) {
      margin-bottom: $spacer-3;
    }
  }
};
