@import "../abstracts/variables";

.private-player-card {
  .ant-card-body {
    padding: 0;
  }
  &_button {
    & svg {
      height: $fontSize-xs;
    }
  }
  &_year {
    min-width: calc(3.5rem + 2.25rem + 4px);
    width: fit-content;
  }
  &_age-group {
    min-width: 3.5rem;
  }
  &_rating {
    min-width: 2.25rem;
  }
  &_teams {
    height: 2rem;
    &-divider {
      display: flex;
      align-items: center;
    }
  }
  &__avatar-block {
    position: relative;
    &__academy {
      position: absolute;
      bottom: 0;
      border: 1px solid $component-bg-color;
      right: 0;
    }
  }
}
