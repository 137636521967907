@import "../abstracts/variables";

.component-text-block-style {
  &__label {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    font-size: $fontSize-sm;
    line-height: 22px;
    color: #BFB298;
    margin-bottom: $spacer-3;
    @media (max-width: $screen-xs) {
      font-size: $fontSize-xxxs;
      margin-bottom: $spacer-2;
    }
  }
  &__value {
    display: flex;
    flex-wrap: nowrap;
  }
  &__symbol {
    background: linear-gradient(180deg, #FFF5C1 0%, #F2CB00 100%);
    border-radius: $spacer-2;
    padding: $spacer-1 $spacer-2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    color: $gray-11;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 46px;
    &:not(:last-child){
      margin-right: $spacer-3;
    }
    @media (max-width: $screen-xs) {
      border-radius: $spacer-2;
      padding: $spacer-1 $spacer-2;
      font-size: $fontSize-xxlg;
    }
  }
};
