@import "../abstracts/variables";

.player-settings-tab-stripe {
  &__tabs-container {
    .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-content-holder{
      display: none;
    }
    .ant-tabs-tab-active {
      background-color: $component-bg-color;
    }
  }
  &.mobile {
    width: 100%;
  }
}
