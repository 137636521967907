@import "../abstracts/variables";

.payment-requirements-modal {
  & .ant-modal-content {
    background-color: $component-bg-color;
  }
  & .ant-modal-body {
    margin-top: $spacer-9;
  }
  &__description {
    word-break: break-word;
    white-space: pre-line;
  }
}
