@import '../abstracts/variables';

.players {
  &-private {
    &-filters {
      @media (max-width: $screen-xs) {
        & .ant-tag-checkable {
          margin-right: 0;
        }
        & .ant-input-number {
          max-width: 60px !important;
        }
        & .switch-select {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }
  &__list {
    li {
      @extend .cursor-pointer;
      border: none !important;
      padding: 0 !important;
      margin-bottom: $spacer-3;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-nodata-list {
    background: url('/images/background-with-logo.png') no-repeat center top;
    background-size: cover !important;
    padding: $spacer-10;
    height: 100% !important;
    @media (min-width: $screen-xs) {
      div.ant-typography {
        font-size: 16px !important;
      }
    }
  }
  &-nodata {
    flex-direction: column;
    width: fit-content;
    margin: auto;
    margin-top: 15vh;
    margin-bottom: 15vh;
    @media (max-width: $screen-lg) {
      width: 100%;
    }
    &__icon {
      margin: $spacer-13;
      width: $icon-empty-list-size;
      height: $icon-empty-list-size;
      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__player-page {
    &__tabs {
      min-width: $player-page-tabs-width;
      width: $player-page-tabs-width;
      max-width: $player-page-tabs-width;
      margin-right: $player-page-tabs-margin-right;
      .ant-tabs-tab .anticon {
        margin-right: $spacer-4 / 2;
      }
    }
    &__content {
      min-width: $player-page-content-width;
      width: $player-page-content-width;
      max-width: $player-page-content-width;
    }
    &.mobile {
      width: 100%;
      .players__player-page__tabs {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        margin-right: $spacer-0;
      }
      .players__player-page__content {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &__test-list {
    &_item {
      .ant-tag {
        border-radius: $border-radius-tag;
      }
      &-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        //width: calc(100% - #{$img-test-width} - #{$spacer-5});
      }
    }
  }
  &__tabs {
    .ant-tabs-nav {
      width: 100%;
    }
    .ant-tabs-content-holder {
      display: none;
    }
    .ant-tabs-tab-active {
      background-color: $component-bg-color;
    }
  }
  &__academy {
    &_card-item {
      .ant-badge-status-dot {
        width: 10px;
        height: 10px;
      }
      .ant-card-body,
      .ant-card {
        padding: $spacer-5;
        border-radius: $border-radius;
      }
      &-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    &__page {
      &__age-selector {
        .ant-select-selector {
          padding: 0 $spacer-3 !important;
          & .ant-select-selection-item {
            @extend .fs-xxs;
            padding-right: $spacer-3;
          }
        }
        .ant-select-arrow {
          right: $spacer-3;
        }
      }
      &__result-card {
        padding: $spacer-3 $spacer-5;
        background: $component-bg-secondary;
        border-radius: $border-radius;
      }
      &__tabs {
        .ant-tabs-nav {
          &:before {
            display: none;
          }
        }
      }
      &__tests {
        &__item {
          margin-bottom: $spacer-5;
          border: 1px solid $gray-9;
          .ant-collapse-header {
            display: flex !important;
            flex-flow: row nowrap;
          }
          &:last-child {
            margin-bottom: $spacer-0;
          }
          & .ant-collapse-content-active {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
          }
          & .ant-collapse-arrow {
            margin-top: 2px;
            top: 4px !important;
          }
        }
      }
    }

    &_badge {
      border-radius: 50%;
      width: $badge-size;
      height: $badge-size;
      &-green {
        background: $cyan-5 !important;
      }
      &-orange {
        background: $orange-5 !important;
      }
      &-yellow {
        background: $color-yellow !important;
      }
      &-red {
        background: $red-6 !important;
      }
    }
  }
  &__videos-tab {
    &__modal {
      &__body {
        & .ant-tabs-top {
          & .ant-tabs-tab {
            font-weight: 400;
            padding-left: $spacer-0;
            padding-right: $spacer-0;
            margin-right: $spacer-9;
          }
          & .ant-tabs-nav {
            &:before {
              display: none;
            }
          }
        }
        & .ant-upload {
          &.ant-upload-drag {
            border: none;
            background-color: $page-bg-color;
            & p.ant-upload-drag-icon .anticon {
              color: $icon-color-additional;
            }
            & .ant-upload-drag-container {
              padding: 0;
            }
          }
        }
      }
    }
    &__list {
      background-color: $component-bg-color;
    }
    &__element {
      margin-bottom: $spacer-5;
      &:last-child {
        margin-bottom: $spacer-0;
      }
      &__edit {
        &__buttons {
          &.mobile {
            flex-direction: column-reverse;
          }
        }
      }
      &__data {
        overflow: hidden;
        & > div {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
