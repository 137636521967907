@import "../abstracts/variables";

$gutter: $spacer-12;
$gutter-sm: $spacer-7;
$gutter-xs: $spacer-4;
$column-width: 230px;
$column-width-sm: 180px;
$column-width-xs: 160px;

@mixin columnStyle {
  min-width: $column-width;
  width: $column-width;
  max-width: $column-width;

  &:not(:last-child) {
    margin-right: $gutter;
  }

  @media (max-width: $screen-sm) {
    min-width: $column-width-sm;
    width: $column-width-sm;
    max-width: $column-width-sm;

    &:not(:last-child) {
      margin-right: $gutter-sm;
    }
  }

  @media (max-width: $screen-xs) {
    min-width: $column-width-xs;
    width: $column-width-xs;
    max-width: $column-width-xs;

    &:not(:last-child) {
      margin-right: $gutter-xs;
    }
  }
}

.players-compare {
  display: flex;
  flex-flow: column nowrap;
  width: fit-content;
  max-width: 100%;

  .ant-spin-nested-loading, .ant-spin-container {
    width: fit-content;
    max-width: 100%;
  }

  &__search {
    &__field {
      min-width: 290px;
    }
  }

  &__overflow {
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: $spacer-4;
    &.no-scroll {
      &::-webkit-scrollbar {
        display: none;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  &__player-card {
    @include columnStyle;
    overflow: visible;
  }
  &__skills {
    &__element {
      @include columnStyle;
    }

    .ant-collapse-header {
      flex-flow: row nowrap;
      display: flex;
      align-items: center;
      margin-bottom: $spacer-4;
    }

    .ant-collapse-header, .ant-collapse-content-box {
      padding: 0 !important;
    }

    .ant-collapse-content {
      min-height: 35px;
      opacity: 1 !important;
    }

    .ant-collapse-content-hidden {
      display: block !important;
      .players-compare__micro-skills__blocks {
        height: 0;
        opacity: 0;
      }
    }
  }
  &__micro-skills {
    &__block {
      margin-bottom: $spacer-3;
      border-bottom: 1px solid $component-bg-third;
    }
    &__element {
      @include columnStyle;
    }
  }
  &__cards {
    top: 20px;
    z-index: 100;
    background-color: $page-bg-color;
    &.sticky {
      position: sticky;
    }
  }
}