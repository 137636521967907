@import "../base/utiles";
@import "../abstracts/variables";

.layout{
  &__content{
    max-width: $max-content-width;
    margin: auto;

    &.isMedium{
      max-width: $layout-medium-width;
    }

    &.isSmall{
      max-width: $screen-sm;
    }
  }
  &__centered-with-logo {
    height: calc(100% - #{$header-height});
    max-width: $max-content-width;
    margin: $header-height auto 0 auto;
    &__children {
      height: 100%;
      padding-bottom: 10%;
      @media (max-width: $screen-lg) {
        padding-bottom: 16%;
      }
    }
  }
}
