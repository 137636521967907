@import "../abstracts/variables";

.avatar-editor {
  &__avatar-container {
    position: relative;
    width: fit-content;
    cursor: pointer;
    &:hover, &:active, &:focus {
      .avatar-editor__avatar-overlay {
        display: flex;
      }
    }
  }
  &__avatar-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba($page-bg-color, 0.5);
    height: 100%;
    width: 100%;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
  }
  &__modal {
    & .ant-modal-body {
      display: flex;
      justify-content: center;
      padding: $spacer-7;
    }
  }
}
