@import "../abstracts/variables";
.academy-edit-page{
  &__title {
    @media (max-width: $screen-lg) {
      margin-left: $spacer-5;
    }
  }
  &__base-content{
    padding-left: $spacer-11 * 3 + $spacer-2 !important;
  }
  &__avatar{
    position: absolute;
    z-index: 1;
    padding-left: $spacer-4 !important;
  }
  &__content{
    @media (min-width: $screen-md) {
      padding-left: $spacer-11 * 3 - $spacer-1 !important;
    }
  }
  &__row{
    width: 172px;
  }
  &__hide-tariffs-row {
    height: 40px;
  }
}
