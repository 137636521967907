.player-skills-block {
  scroll-snap-type: x mandatory;
  &__item {
    scroll-snap-align: center;
    opacity: 0.5;
    &.unselected {
      opacity: 1;
    }
    &__title {
      max-width: 75%;
    }
  }
}
