@import "../abstracts/variables";

$player-avatar-width: 68%;
$scaleSize: 0.75;
$groupHeight: 53%;

.player-shield-small {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-content: flex-start;
  flex-flow: column nowrap;
  justify-content: space-between;

  &__rating {
    font-size: 38px * $scaleSize !important;
  }

  &__age-group {
    font-size: 24px * $scaleSize !important;
  }

  &__playerName {
    font-size: 24px * $scaleSize !important;
  }

  &-component {
    position: relative;
    width: $player-shield-width * $scaleSize;
    min-width: $player-shield-width * $scaleSize;
    max-width: $player-shield-width * $scaleSize;
  }
  &__top {
    width: 100%;
    height: 48%;
    min-height: 48%;
    position: relative;
    &__info {
      width: 100%;
      height: 100%;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      padding: $spacer-1 * $scaleSize - 1 $spacer-1 * $scaleSize 0 $spacer-1 * $scaleSize - 1;
      border-top-right-radius: 10%;
      overflow: hidden;
      &__wrapper {
        position: relative;
        height: 100%;
        margin-left: $spacer-6  * $scaleSize;
        & > * {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &__block-wrapper {
        width: 100% - $player-avatar-width;
        font-size: small;
      }
      &__group {
        z-index: 0;
        height: $groupHeight;
        align-items: flex-end;
        border-radius: 20px 0 0 20px;
        padding-bottom: $spacer-5 * $scaleSize;
        div {
          color: $gray-1 !important;
        }
      }
      &__rating {
        z-index: 1;
        height: 30%;
        padding-top: $spacer-3;
        border-radius: 21px 20px 0 20px;
        & > div {
          padding-right: $spacer-2 * $scaleSize;
        }
      }
      &__additional {
        top: unset;
        bottom: 0;
        width: 100% - $player-avatar-width;
      }
    }
    &__icon {
      padding: 1px 1px 0 0;
      width: $player-avatar-width * $scaleSize;
      border: 1px solid $pp-color-black;
      border-top-width: 0;
      border-right-width: 0;
      border-top-left-radius: 10%;
      border-bottom-left-radius: 10%;
      border-top-right-radius: 11%;
      & > * {
        border-top-left-radius: 9%;
        border-bottom-left-radius: 9%;
        border-top-right-radius: 11%;
      }
      &__avatar {
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100%;
        background-color: $gray-1;
      }
      &__default-avatar {
        height: 100%;
        background-color: $component-bg-color;
      }
    }
  }
  &__skills {
    padding: 0 $spacer-4 * $scaleSize;
    &__wrapper {
      border-top: 1px solid $gray-11;
      padding-top: $spacer-3 * $scaleSize;
      & .ant-typography {
        font-size: 24px * $scaleSize !important;
      }
    }
    &__divider {
      width: 1px;
      height: 100%;
      background-color: $gray-11;
    }
  }
  &__info {
    padding: 0 $spacer-4 * $scaleSize;
    &__name {
      max-width: 81%;
      height: 48px  * $scaleSize;
      display: flex;
      align-items: center;
      & > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &__academy-avatar {
    border-radius: 100%;
    object-fit: cover;
    margin-top: $spacer-5 * $scaleSize;
  }
  &__leg-icon {
    width: 36 * $scaleSize;
    height: auto;
  }
  &__logo {
    padding: 12px * $scaleSize 12px * $scaleSize 7% 12px * $scaleSize;
  }
}
