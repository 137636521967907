@import "../abstracts/variables";

.header {
  &__logo {
    .ant-btn-primary {
      color: $text-color-additional !important;
      border-color: $text-color-additional !important;
    }
    .ant-btn-default {
      &:hover, &:active, &:focus {
        color: darken($text-color-additional, 10%);
        border-color: darken($text-color-additional, 10%);
      }
    }
  }
}

.header-btn-icon {
  &.active {
    color: $text-color-additional;
  }
}