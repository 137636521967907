@import '../abstracts/variables';

.player-skills-detail {
  &__header {
    border-radius: 6px 6px 0 0;
    background: $geekblue-1;
    border: 1px solid $gray-5;
    border-bottom: 0;
  }
  &__list {
    border-radius: 0 0 6px 6px;
    border: 1px solid $gray-5;

    .ant-list-item {
      border-color: $gray-5 !important;
    }
  }
}
