@import "../abstracts/variables";

$icons-width: 16px;

.subscriptions-compare {
  margin-bottom: $spacer-11;
  &__free-block, &__not-free-block {
    border-radius: $border-radius;
    background-color: $component-bg-color;
    border-top-width: 7px;
    border-top-style: solid;
    padding: $spacer-4 $spacer-5 $spacer-7 $spacer-5;
    flex: 0 0 48%;
    max-width: 48%;
    height: fit-content;
    &__tag {
      padding: $spacer-3 $spacer-5;
      border-style: solid;
      border-width: 1px;
      border-radius: $border-radius;
    }
    &__info {
      svg {
        padding-top: 2px;
        min-width: $icons-width;
        width: $icons-width;
        max-width: $icons-width;
      }
      h5 {
        display: flex;
        align-items: flex-start;
      }
    }
  }
  &__free-block {
    border-top-color: $yellow-6;
    &__tag {
      border-color: $red-5;
    }
  }
  &__not-free-block {
    border-top-color: $gold-5;
    &__tag {
      border-color: $gold-5;
    }
  }
  &__mobile {
    border-radius: $border-radius;
    background-color: $component-bg-color;
    height: fit-content;
    svg {
      padding-top: 2px;
      min-width: $icons-width;
      width: $icons-width;
      max-width: $icons-width;
    }
  }
}
