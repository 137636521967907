@import '../abstracts/variables';
@import '../abstracts/colors';

// Rate
.ant-rate-star {
  &-full {
    color: $yellow-6;
    font-size: $font-main, $font-backup !important;
    margin-top: -1px !important;
  }
}

// Buttons
@mixin btn-color-primary($color, $text-color: $text-color-primary) {
  &:focus {
    background: $color;
    border-color: $color;
    color: $text-color;
  }
  &:hover {
    background: darken($color, 20);
    border-color: darken($color, 20);
    color: $text-color;
  }
}

@mixin btn-color-default($color, $border-color, $active-color) {
  &:focus {
    color: $color;
    border-color: $border-color;
  }
  &:hover {
    color: $active-color;
    border-color: $active-color;
  }
}

.ant-btn {
  @include btn-color-default($text-color-secondary, $color-alternative, darken($color-alternative, 20));
  &-dangerous {
    @include btn-color-default($red-8, $red-8, darken($red-8, 20));
  }
  &-primary {
    text-shadow: none;
    @include btn-color-primary($color-primary);
    &.ant-btn-dangerous {
      @include btn-color-primary($text-color-danger, $text-color-light);
    }
  }
  &-link {
    &:focus {
      color: $icon-color-additional;
      border: none;
    }
    &:hover {
      color: darken($icon-color-additional, 20);
      border: none;
    }
  }
  &-text {
    border: none !important;
    &:focus {
      background: unset;
    }
    &:focus {
      background: $color-true-black-018;
    }
  }
}

.ant-btn,
.ant-btn-primary,
.ant-btn-dangerous,
.ant-btn-default {
  border-radius: $border-radius !important;

  &.ant-btn-circle {
    border-radius: 50% !important;
  }
}

.ant-btn-dangerous {
  &.ant-btn-default,
  &.ant-btn-dashed,
  &.ant-btn-link,
  &.ant-btn-text {
    color: $text-color-danger !important;
  }
}

.ant-btn-background-ghost.ant-btn-dangerous {
  &:hover,
  &:active {
    color: $gray-1 !important;
  }
}

// Typography
h1,
h1.ant-typography,
.ant-typography h1 {
  font-weight: 700;
  font-size: 38px;
  line-height: 103%;
}

h2,
h2.ant-typography,
.ant-typography h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 108%;
}

h3,
h3.ant-typography,
.ant-typography h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 113%;
}

h4,
h4.ant-typography,
.ant-typography h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 119%;
}

h5,
h5.ant-typography,
.ant-typography h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
}

span.ant-typography {
  font-weight: 400;
  font-size: 14px;
  line-height: 134%;

  & > strong {
    font-weight: 700;
    font-size: 14px;
    line-height: 109%;
  }

  & > u {
    font-weight: 400;
    font-size: 14px;
    line-height: 134%;
  }

  &.ant-typography-secondary {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 134% !important;
  }
}

div.ant-typography {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 142% !important;
}

a.ant-typography {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 134% !important;
  text-decoration: underline;
  color: $text-color-additional;
}

// Input
.ant-input,
.ant-input-password,
.ant-picker,
.ant-skeleton-input,
.ant-input-number {
  border-radius: $border-radius !important;
  box-shadow: none;

  & .ant-input-number-handler-wrap {
    border-radius: 0 $border-radius $border-radius 0;
  }
}

textarea.ant-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  padding: $spacer-4;
}

.ant-skeleton {
  width: 100%;
}
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-lg {
  border-radius: 6px;
}

// Form
.ant-form {
  &-item {
    &-required {
      &:before {
        content: none !important;
      }
    }
    &-has-error {
      & .ant-form-item-control-input-content {
        & > .ant-input-affix-wrapper,
        input,
        .ant-input {
          border-color: $red-5 !important;
        }
      }
      & .ant-form-item-explain-error {
        color: $red-5 !important;
      }
      .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
        border-color: $red-5 !important;
      }
    }
  }
}

// Select
.ant-select {
  &-selector {
    border-radius: $border-radius !important;
  }

  &-arrow {
    color: $color-character-disabled-amp-placeholder-25 !important;
  }
}

// Card
.ant-card {
  border-radius: $border-radius;
  &-hoverable {
  }
  &-body {
    background: $component-bg-color;
    border-radius: $border-radius;
    padding: $spacer-5 $spacer-3;
  }
}
//Alert
.ant-alert-icon {
  margin-top: 4px;
}

//Popover
.ant-popover {
  &-message {
    & .anticon-exclamation-circle {
      color: $color-danger;
      height: 12px;
    }
  }
  &-inner {
    &-content {
      padding: $spacer-5;
      border-radius: $border-radius-xs;
    }
  }
}

// Modal
.ant-modal {
  &-content {
    padding: $spacer-5;
    border-radius: $border-radius;
  }

  &-header {
    border: none;
    padding: 0 0 $spacer-5;
  }

  &-body {
    border: none;
    padding: 0;
  }

  &-title {
    @extend h3;
  }
}

// Radio
.ant-radio {
  &-group {
    &.width-full {
      display: flex;
      width: 100%;
      & > .ant-radio-button-wrapper {
        flex: 1;
        text-align: center;
      }
    }
  }
  &-checked {
    height: fit-content;
  }
}

// Radio.Group Buttons
.ant-radio-button-wrapper {
  &:first-child {
    border-radius: $border-radius 0 0 $border-radius;
  }

  &:last-child {
    border-radius: 0 $border-radius $border-radius 0;
  }
  &:not(&-button-wrapper-checked) {
    @include btn-color-default($text-color-secondary, $color-alternative, darken($color-alternative, 20));
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: $text-color-primary;

  &:hover {
    color: $text-color-primary;
  }
}

.ant-radio-button-wrapper,
.ant-radio-button-wrapper .ant-radio-button-wrapper-checked {
  @extend .ellipsis;
}

.ant-radio-button-wrapper {
  border-left: 1px solid $border-color;
}

// Tabs
.ant-tabs {
  color: $color-true-white-065;
}

.ant-tabs-tab {
  color: $text-color-secondary;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  padding: $spacer-3 $spacer-3 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-shadow: none;
}

.ant-tabs-tab .anticon {
  margin-right: $spacer-1;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid $gray-9;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border: 1px solid $gray-10;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: $border-radius $border-radius 0 0;
}

.ant-tabs-top .ant-tabs-tab {
  margin-right: 0;
  padding-left: $spacer-6;
  padding-right: $spacer-6;
}

.ant-tabs-nav-operations,
.ant-tabs-dropdown {
  display: none !important;
}

.ant-tabs-tab {
  padding: $spacer-4 $spacer-3;
}

// Badge
.ant-badge {
  &-dot {
    background: $color-danger;
    margin: $spacer-2;
  }
}

// Dropdown-menu
.ant-dropdown-menu {
  &-item {
    &-divider {
      margin: 0;
    }
  }
}

.ant-carousel .slick-dots li button {
  background: $gray-1;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px;
  opacity: 0.4;
}

.ant-steps-item-wait > .ant-steps-item-container {
  .ant-steps-item-icon {
    background-color: $component-bg-third;
    border-color: $component-bg-third;
  }
  .ant-steps-icon {
    color: $pp-color-black;
  }
}
.ant-steps-item-process > .ant-steps-item-container {
  .ant-steps-item-icon {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $pp-color-black;
  }
  .ant-steps-icon {
    color: $pp-color-black !important;
  }
}
.ant-steps-item-finish > .ant-steps-item-container {
  .ant-steps-item-icon {
    background-color: $color-green;
    border-color: $color-green;
  }
  .ant-steps-icon {
    color: $pp-color-black;
  }
}

// LINE
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 2px;
  background-color: $pp-color-black;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 2px;
  background-color: $pp-color-black;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  height: 2px;
  background-color: $pp-color-black;
}
.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  width: 10px !important;
  height: 10px !important;
  border-radius: 100px;
  background: $gray-1;
}

.ant-carousel .slick-dots li.slick-active {
  width: 16px !important;
}

.ant-carousel .slick-dots-bottom {
  bottom: -30px;
}

.ant-list {
  &-bordered {
    border-color: $border-color;
    border-radius: $border-radius;
    & .ant-list-item {
      border-color: $border-color;
    }
  }
}

.ant-pagination {
  &-disabled {
    & svg {
      color: inherit !important;
    }
  }
  &-item {
    &-active {
      border-color: $color-alternative;
      border-radius: $spacer-3;
      & > a {
        color: $color-alternative;
      }
      &:hover {
        border-color: darken($color-alternative, 10%);
      }
    }
    &-link {
      &:hover {
        & svg {
          color: darken($color-alternative, 10%);
        }
      }
    }
    &:hover {
      & > a {
        color: darken($color-alternative, 10%);
      }
    }
  }
}

// Message
.ant-message {
  z-index: 10000;
}

.ant-divider {
  &-inner-text {
    font-size: $fontSize-xxxs;
    color: $color-true-black-04;
  }
}
