@import "../abstracts/variables";

.player-test-results{
  max-height: 60vh;
  overflow: auto !important;
}

.player-test-results-modal{
  &__body{
    & .ant-list{
      &-items{
        & > .ant-list-item:not(:last-child){
          & .ant-collapse{
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
        & > .ant-list-item:not(:first-child){
          & .ant-collapse{
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
        & > .ant-list-item:last-child{
          & .ant-collapse{
            border: 1px solid $gray-9;
          }
        }
      }
      &-item{
        padding: 0;
        & .ant-collapse-item{
          border: 0 !important;
        }
      }
    }
  }
}
