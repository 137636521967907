@import "../abstracts/variables";

.line-chart {
  &__oy{
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    pointer-events: none;
  }
}

.line-chart-instruments {
  position: relative;
  &__scale{
    position: absolute;
    top: 0;
    right: 0;
    background: $component-bg-third;
    z-index: 2;
    border-radius: $border-radius;
    padding: $spacer-1;
    opacity: .25;
    transition-property: opacity;
    transition-duration: 500ms;
    &:hover{
      opacity: 1;
    }
  }
  .ant-spin-blur {
    opacity: 0;
  }
}
