@import "../abstracts/variables";

.academy-edit-tests-page {
  &__affix-container {
    height: $button-large;
    & > .ant-row {
      height: $button-large;
    }
    &.mobile {
      height: 0;
      & > .ant-row {
        height: 0;
      }
    }
  }
  &__affix-content {
    position: fixed;
    z-index: 1000;
    &.mobile {
      width: 70%;
      bottom: 1.5%;
    }
  }
}
